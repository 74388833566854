/* eslint-disable @typescript-eslint/no-empty-function */
import { useLocation } from 'react-router-dom';
import Layout from '../../../components/Layout/Report';
import Content from '../../../components/Pages/Reports/Edit';
import { displayName } from '../../../config';
import ReportsProvider from '../../../context/reports';

const ReportDetails = (): JSX.Element => {
  const location = useLocation();

  const isEditPath = location.pathname.includes('/edit');
  const isPreview = location.pathname.includes('/preview');

  document.title = !isEditPath || !isPreview ? 'Your Mortgage Review' : `${displayName}: Report Details`;

  return (
    <ReportsProvider value={{
      isBorrower: false,
      previewLoading: false,
      closingCostsLoading: false,
      setPreviewLoading: () => { },
      setClosingCostsLoading: () => { }
    }}>
      <Layout>
        <Content isLoading={false} />
      </Layout>
    </ReportsProvider>
  );
};

export default ReportDetails;
