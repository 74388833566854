import { Typography } from 'antd';
import debounce from 'just-debounce-it';
import { JsonResult } from '../../../types';
import { EnumReportTypes, ILoanColumn, IReportRow } from '../../../types/reports';
import { commafy, toFixed, toFloat } from '../../../utils/text';
import CustomInput from '../../Common/CustomInput';
import {
  Wallet,
  Piggy,
  TimeCoin,
  Calculator,
  Home,
  Coins,
  BankPercent,
  HandBanknote,
  BankCoin,
  DollarSheald,
  CardPin,
  CointGrowth,
  MoneyBug,
} from '../../Common/Icon';
import GetQuote from './GetQuote';
import { IEvent } from './types';
import { EnumTimeOptions } from '../../../hooks/userViews';
import { EnumClosingCostType } from '../../../types/settings';
import ClosingCostsDetails from './ClosingCostsDetails';
import { calculatePrincipalInterest, calculateUip } from '../../../utils/loan';

export interface IDataSetRA {
  id: number;
  label: string;
  name: 'avg' | 'bedrooms';
  value: boolean | number;
  color: string;
  isActive: boolean;
}

interface ILongTermDataSet {
  label: string;
  name: 'taxBeretta' | 'appreciationGain' | 'amortisationGain';
  color: string;
  details?: string;
}

interface IDataSet {
  label: string;
  color: string;
  name: 'rent' | 'own';
}

interface SupplementalStates {
  [key: string]: string;
}

const supplementalStates: SupplementalStates = {
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  HI: 'Hawaii',
  IL: 'Illinois',
  IA: 'Iowa',
  MN: 'Minnesota',
  NB: 'Nebraska',
  OR: 'Oregon',
  WA: 'Washington',
};

export const TIME_FILTERS = [
  { label: 'One Week', value: EnumTimeOptions.One_Week },
  { label: 'Two Weeks', value: EnumTimeOptions.Two_Weeks },
  { label: 'One Month', value: EnumTimeOptions.One_Month },
  { label: 'Two Months', value: EnumTimeOptions.Two_Months },
  { label: 'Three Months', value: EnumTimeOptions.Three_months },
  { label: 'Six Months', value: EnumTimeOptions.Six_Months },
  { label: 'One Year', value: EnumTimeOptions.One_Year },
  { label: 'All Time', value: EnumTimeOptions.ALL_TIME },
];

export const FEATURES: { [key: string]: string } = {
  mortgage_presentation_tools: 'Mortgage presentation tools',
  unlimited_reports: 'Unlimited reports',
  property_data_copilot: 'Property data Copilot',
  breaking_news: 'Breaking news',
  market_charts: 'Market charts',
  content_scripts: 'Content scripts',
  market_rate_lock_alerts: 'Market rate lock alerts',
  premium_support: 'Premium support',
  early_access_new_features: 'Early access to new features',
  ceo_cell_phone: "CEO's cell phone number",
};

const extractValue = (field: any) => {
  if (typeof field === 'object' && field !== null && 'value' in field) {
    return Number(String(field.value).replace(/,/g, '')) || 0;
  }

  return Number(String(field).replace(/,/g, '')) || 0;
};

const getFieldValue = (field: { value: any } | any) => {
  // eslint-disable-next-line no-prototype-builtins
  if (typeof field === 'object' && field !== null && field.hasOwnProperty('value')) {
    return field.value;
  }

  return field;
}

export const ROWS: IReportRow[] = [
  {
    id: 1,
    tabIndex: 1,
    order: 1,
    active: true,
    name: 'purchasePrice',
    text: 'Purchase Price',
    prefix: '$',
    note: 'purchasePriceNote',
    styles: { height: 50 },
    placeholder: '$0',
    onChange: (form, key, value) => {
      const downPaymentPercentage = form.getFieldValue(['loans', key, 'downPaymentPercentage', 'value']);
      const homeInsurancePercentage = form.getFieldValue(['loans', key, 'homeInsurancePercentage']);

      if (!value || Number.isNaN(value)) {
        form.setFieldValue(['loans', key, 'downPayment', 'value'], 0);
        form.setFieldValue(['loans', key, 'loanAmount', 'value'], 0);

        return;
      }

      const downPayment = Number(value) * (downPaymentPercentage / 100);
      const loanAmount = Number(value) - downPayment;

      if (homeInsurancePercentage && value) {
        form.setFieldValue(
          ['loans', key, 'homeInsurance', 'value'],
          toFixed((toFloat(value) * (toFloat(homeInsurancePercentage) / 100)) / 12, 2))
      }

      const percentage = form.getFieldValue(['loans', key, 'propertyTaxesPercentage', 'value']);

      if (percentage) {
        const propertyTaxes = (percentage * toFloat(value)) / (12 * 100);

        form.setFieldValue(['loans', key, 'propertyTaxes', 'value'], toFloat(propertyTaxes));
      }
      form.setFieldValue(['loans', key, 'priceStatus'], null);
      form.setFieldValue(['loans', key, 'downPayment', 'value'], downPayment);
      if (downPayment) form.setFieldValue(['loans', key, 'loanAmount', 'value'], loanAmount);
      form.setFieldValue(['loans', key, 'deposit_money_price'], Number(value) * 0.03);
    },
  },
  {
    id: 2,
    tabIndex: 2,
    order: 2,
    active: true,
    name: 'downPayment',
    note: 'downPaymentNote',
    text: 'Down Payment',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    onChange: (form, key, value) => {
      const purchasePrice = form.getFieldValue(['loans', key, 'purchasePrice', 'value']);

      const downPaymentPercentage = (toFloat(value) / toFloat(purchasePrice)) * 100;

      const newValue = Number.isNaN(downPaymentPercentage) ? '0' : toFixed(downPaymentPercentage, 2);

      form.setFieldValue(['loans', key, 'downPaymentPercentage', 'value'], newValue);

      if (!purchasePrice || Number.isNaN(toFloat(value))) return;

      const newLoanAmount = toFloat(purchasePrice) - toFloat(value);

      form.setFieldValue(['loans', key, 'loanAmount', 'value'], newLoanAmount);

      // Use this later
      // const uipActive = form.getFieldValue(['loans', key, 'addUip']);

      setTimeout(() => {
        const uip = form.getFieldValue(['loans', key, 'uip', 'value']);
        const loanAmountWithUip = toFloat(newLoanAmount) + toFloat(uip);

        form.setFieldValue(['loans', key, 'loanAmountWithUip', 'value'], loanAmountWithUip);
      }, 850)
    },
    prepareData: (loan) => {
      const { purchasePrice, downPayment, downPaymentPercentage } = loan;

      if (downPayment?.value) return downPayment.value;

      if (!downPaymentPercentage?.value || !purchasePrice?.value) return 0;

      const newDownPayment = (toFloat(purchasePrice.value) * toFloat(downPaymentPercentage.value)) / 100;

      return Number.isNaN(newDownPayment) ? '0' : commafy(newDownPayment);
    },
    subField: {
      tabIndex: 3,
      name: 'downPaymentPercentage',
      suffix: '%',
      order: 1,
      placeholder: '0%',
      onChange: (form, key, value) => {
        const purchasePrice = form.getFieldValue(['loans', key, 'purchasePrice', 'value']);

        const downPayment = (toFloat(value) * toFloat(purchasePrice)) / 100;

        const newValue = Number.isNaN(downPayment) ? '0' : toFixed(downPayment, 2);

        form.setFieldValue(['loans', key, 'downPayment', 'value'], newValue);

        if (!purchasePrice || Number.isNaN(downPayment)) return;

        const newLoanAmount = toFloat(purchasePrice) - toFloat(downPayment);

        form.setFieldValue(['loans', key, 'loanAmount', 'value'], newLoanAmount);
      },
      prepareData: (loan) => {
        const { purchasePrice, downPayment, downPaymentPercentage } = loan;

        if (downPaymentPercentage?.value) return downPaymentPercentage.value;

        if (!downPayment?.value || !purchasePrice?.value) return undefined;

        const newDownPaymentPercentage = (toFloat(downPayment.value) / toFloat(purchasePrice.value)) * 100;

        return Number.isNaN(newDownPaymentPercentage) ? '0' : toFixed(newDownPaymentPercentage, 2);
      },
      options: () => [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 1,
          label: '1.0%',
        },
        {
          value: 3.5,
          label: '3.5%',
        },
        {
          value: 5,
          label: '5%',
        },
        {
          value: 10,
          label: '10%',
        },
        {
          value: 15,
          label: '15%',
        },
        {
          value: 20,
          label: '20%',
        },
        {
          value: 25,
          label: '25%',
        },
        {
          value: 30,
          label: '30%',
        },
      ],
    },
  },
  {
    id: 3,
    tabIndex: 4,
    order: 3,
    active: true,
    name: 'loanAmount',
    text: 'Loan Amount',
    note: 'loanAmountNote',
    prefix: '$',
    onChange: (form, key, value) => {
      const purchasePrice = form.getFieldValue(['loans', key, 'purchasePrice', 'value']);

      const downPayment = toFloat(purchasePrice) - toFloat(value);
      const downPaymentPercentage = (toFloat(downPayment) / toFloat(purchasePrice)) * 100;

      form.setFieldValue(['loans', key, 'downPayment', 'value'], downPayment);
      form.setFieldValue(['loans', key, 'downPaymentPercentage', 'value'], toFixed(downPaymentPercentage, 2));

      // Update loan amount with uip when change loan amount
      const uip = form.getFieldValue(['loans', key, 'uip', 'value']);
      const loanAmountWithUip = toFloat(value) + toFloat(uip);

      form.setFieldValue(['loans', key, 'loanAmountWithUip', 'value'], loanAmountWithUip);
    },
    placeholder: '$0',
    styles: { height: 50 },
    subField: {
      text: 'Upfront Ins Premium',
      tabIndex: 4,
      name: 'loanAmountWithUip',
      suffix: ' (with Upfront Ins.)',
      prefix: '$',
      disabled: () => true,
      order: 1,
      placeholder: '0$',
      prepareData: (loan) => {
        const { uip, loanAmount, loanAmountWithUip } = loan;

        if (loanAmountWithUip?.value) {
          return toFixed(loanAmountWithUip.value, 2);
        }

        if (!uip?.value || !loanAmount?.value) return '0';

        const value = Number(uip?.value) + Number(loanAmount?.value);

        return Number.isNaN(value) ? '0' : toFixed(value, 2);
      },
    },
  },
  {
    id: 4,
    tabIndex: 5,
    order: 4,
    active: true,
    name: 'loanPeriodInYears',
    note: 'loanPeriodInYearsNote',
    text: 'Loan Term',
    suffix: ' years',
    details: () => (
      <div className="flex-col" style={{ maxWidth: 300 }}>
        Loan term is the length of time you have to repay your mortgage. It is typically 15, 20, or 30 years.
      </div>
    ),
    styles: { height: 50 },
    prepareData: (loan) => Number(getFieldValue(loan.loanPeriodInYears)),
    options: () => [
      {
        value: 15,
        label: '15 years',
      },
      {
        value: 30,
        label: '30 years',
      },
    ],
  },
  {
    id: 5,
    tabIndex: 6,
    order: 5,
    active: true,
    name: 'interestRate',
    text: 'Interest Rate',
    suffix: '%',
    note: 'interestRateNote',
    placeholder: '0%',
    details: () => (
      <div className="flex-col" style={{ maxWidth: 300 }}>
        Interest rate is the percentage of the principal balance of your mortgage that you pay to the lender each year.
      </div>
    ),
    styles: { height: 50 },
    options: () => {
      const options = [];

      for (let i = 4; i <= 10; i += 0.125) {
        options.push({ value: i, label: `${i}%` });
      }

      return options;
    },
  },
  {
    id: 6,
    order: 6,
    active: true,
    name: 'apr',
    text: 'APR',
    note: 'aprNote',
    details: () => (
      <div className="flex-col" style={{ maxWidth: 300 }}>
        Annual percentage rate (APR) is the total cost of a mortgage loan, expressed as a percentage. It includes the
        interest rate plus other fees, such as origination fees, appraisal fees, and title insurance.
      </div>
    ),
    suffix: '%',
    placeholder: '0%',
    disabled: () => false,
    styles: { height: 50 },
    onChange: (form, key, value, handleLoansUpdate) => {
      const apr = form.getFieldValue(['loans', key, 'apr', 'value']);

      const debouncedHandleLoansUpdate = debounce(() => {
        if (handleLoansUpdate) {
          handleLoansUpdate();
        }

      }, 300); // 300ms delay

      debouncedHandleLoansUpdate();
    },
    prepareData: (loan, loans) => {
      const { loanAmount, interestRate, aprRelatedClosingCost, loanPeriodInYears, apr: existsApr } = loan;

      if (Number(existsApr?.value)) {
        return existsApr.value
      }

      if (!loanAmount?.value || !interestRate?.value || !loanPeriodInYears?.value) {
        return '0';
      }

      let aprRelatedCostValue: string | number = 0;

      if (typeof aprRelatedClosingCost === 'object') {
        aprRelatedCostValue = aprRelatedClosingCost?.value || 0;
      } else if (typeof aprRelatedClosingCost === 'string' || typeof aprRelatedClosingCost === 'number') {
        aprRelatedCostValue = aprRelatedClosingCost;
      }

      // aprRelatedClosingCost is 0 that's why APR is not calculating
      if (!aprRelatedClosingCost || aprRelatedCostValue === 0) {
        return '0';
      }

      const interestRateValue = toFloat(interestRate.value) / 100;

      const interest =
        (toFloat(loanAmount.value) + toFloat(aprRelatedCostValue)) *
        interestRateValue *
        toFloat(loanPeriodInYears.value);

      const apr =
        ((interest + toFloat(aprRelatedCostValue)) /
          toFloat(loanAmount.value) /
          (toFloat(loanPeriodInYears.value) * 365 + Math.ceil(toFloat(loanPeriodInYears.value) / 4))) *
        365 *
        100;

      return Number.isNaN(apr) ? '0' : apr.toFixed(3);
    },
    getPopoverContent: (field, rowsLength, form, handleLoansUpdate) => {
      const name = 'aprRelatedClosingCost';

      const debouncedHandleLoansUpdate = debounce(() => {
        handleLoansUpdate?.();
      }, 300); // 300ms delay

      return (
        <div className="p-inline-10 p-block-10" style={{ maxWidth: 230 }}>
          <CustomInput
            formItemProps={{
              ...field,
              className: 'label-dark',
              name: [field.name, name],
              label: 'APR Related Closing Cost',
              labelCol: { span: 24 },
            }}
            tabIndex={15 + field.name * rowsLength}
            identifier={['loans', field.name, name]}
            prefix="$"
            // eslint-disable-next-line consistent-return
            onChange={(val) => {
              const loans = form.getFieldValue('loans');

              const loan = loans[field.name];
              const { loanAmount, interestRate, aprRelatedClosingCost, loanPeriodInYears, apr: existsApr } = loan;


              if (!loanAmount?.value || !interestRate?.value || !loanPeriodInYears?.value) {
                return '0';
              }

              let aprRelatedCostValue: string | number = 0;

              if (typeof aprRelatedClosingCost === 'object') {
                aprRelatedCostValue = aprRelatedClosingCost?.value || 0;
              } else if (typeof aprRelatedClosingCost === 'string' || typeof aprRelatedClosingCost === 'number') {
                aprRelatedCostValue = aprRelatedClosingCost;
              }

              // aprRelatedClosingCost is 0 that's why APR is not calculating
              if (!aprRelatedClosingCost || aprRelatedCostValue === 0) {
                return '0';
              }

              const interestRateValue = toFloat(interestRate.value) / 100;

              const interest =
                (toFloat(loanAmount.value) + toFloat(aprRelatedCostValue)) *
                interestRateValue *
                toFloat(loanPeriodInYears.value);

              const apr =
                ((interest + toFloat(aprRelatedCostValue)) /
                  toFloat(loanAmount.value) /
                  (toFloat(loanPeriodInYears.value) * 365 + Math.ceil(toFloat(loanPeriodInYears.value) / 4))) *
                365 *
                100;

              const finalApr = Number.isNaN(apr) ? '0' : apr.toFixed(3);

              form.setFieldValue(['loans', field.name, 'apr', 'value'], finalApr);

              debouncedHandleLoansUpdate();
            }}
            bordered
          />
        </div>
      );
    }
  },
  {
    id: 7,
    tabIndex: 7,
    order: 7,
    active: true,
    name: 'loanPoints',
    note: 'loanPointsNote',
    text: 'Loan Points',
    details: () => (
      <div className="flex-col" style={{ maxWidth: 300 }}>
        Loan points are a one-time upfront fee that you can pay to lower your interest rate on your mortgage. Each point
        equals 1% of your loan amount.
      </div>
    ),
    prefix: '$',
    placeholder: '$0.00',
    styles: { height: 50 },
    disabled: (loan) => !loan.loanAmount?.value,
    onChange: (form, key, value, _, loan) => {
      const oldValue = loan?.loanPoints?.value; 
      const closingCosts = form.getFieldValue(['loans', key, 'closingCosts', 'value']);
      const newTotal = Number(closingCosts) - Number(oldValue) + Number(value);

      form.setFieldValue(['loans', key, 'closingCosts', 'value'], newTotal);
      form.setFieldValue(['loans', key, 'loanPoints', 'value'], value);
    },
    prepareData: (loan) => {
      const { loanPointsPercentage, loanAmount, loanPoints } = loan;

      if (loanPoints?.value) return loanPoints.value;

      if (!loanPointsPercentage?.value || !loanAmount?.value) return undefined;

      const newLoanPoints = toFloat(loanPointsPercentage.value) * toFloat(loanAmount.value) * 100;

      return Number.isNaN(newLoanPoints) ? '0' : toFixed(newLoanPoints, 2);
    },
    subField: {
      tabIndex: 8,
      name: 'loanPointsPercentage',
      order: 1,
      suffix: '%',
      placeholder: '0%',
      disabled: (loan) => !loan.loanAmount?.value,
      onChange: (form, key, value, handleLoansUpdate) => {
        if (!value || value === '0') {
          const previousLoanPoints = form.getFieldValue(['loans', key, 'loanPoints', 'value']);

          form.setFieldValue(['loans', key, 'loanPoints', 'value'], '0.00');

          setTimeout(() => {
            const closingCosts = form.getFieldValue(['loans', key, 'closingCosts', 'value']);
            const newClosingCosts = toFloat(closingCosts || 0) - toFloat(previousLoanPoints || 0);

            form.setFieldValue(['loans', key, 'closingCosts', 'value'], toFixed(newClosingCosts, 2));

            handleLoansUpdate?.();
          }, 1000);
        } else {
          const loanAmount = form.getFieldValue(['loans', key, 'loanAmount', 'value']);
          const previousLoanPoints = form.getFieldValue(['loans', key, 'loanPoints', 'value']);

          const loanPoints = (toFloat(value) * toFloat(loanAmount)) / 100;
          const newLoanPointsValue = Number.isNaN(loanPoints) ? '0' : toFixed(loanPoints, 2);

          form.setFieldValue(['loans', key, 'loanPoints', 'value'], newLoanPointsValue);

          setTimeout(() => {
            const closingCosts = form.getFieldValue(['loans', key, 'closingCosts', 'value']);
            const newClosingCosts = toFloat(closingCosts || 0)
              - toFloat(previousLoanPoints || 0) + toFloat(newLoanPointsValue || 0);

            form.setFieldValue(['loans', key, 'closingCosts', 'value'], toFixed(newClosingCosts, 2));

            handleLoansUpdate?.();
          }, 1000);
        }
      },
      prepareData: (loan) => {
        const { loanPoints, loanAmount, loanPointsPercentage } = loan;

        if (loanPointsPercentage?.value) return loanPointsPercentage.value;

        if (!loanPoints?.value || !loanAmount?.value) return undefined;

        const newLoanPointsPercentage = (toFloat(loanPoints.value) / toFloat(loanAmount.value)) * 100;

        return Number.isNaN(newLoanPointsPercentage) ? '0' : toFixed(newLoanPointsPercentage, 2);
      },
    },
  },
  {
    id: 8,
    tabIndex: 9,
    order: 8,
    active: true,
    name: 'pmi',
    text: 'Mortgage insurance',
    note: 'pmiNote',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    options: () => MORTGAGE_INSURANCE_OPTIONS,
    onChange: (form, key, value) => {

      // First, we need to check if the user manually inputted a dollar amount, or if they selected a percent value.

      // If the user selected a percent value, we need to calculate the mortgage insurance 
      // based on the loan amount and the selected percent value.

      // Otherwise, we simply allow the value to be set as is.

      const mortgageInsuranceRate = toFloat(value);

      const selectedOption = MORTGAGE_INSURANCE_OPTIONS.find((option) => option.value === mortgageInsuranceRate);

      if (selectedOption) {
        const loanAmount = toFloat(form.getFieldValue(['loans', key, 'loanAmount', 'value']));
        const mortgageInsurance = (
          (loanAmount * (mortgageInsuranceRate / 100)) / 12
        ).toFixed(2); // should be divided by 12 to get monthly rate

        form.setFieldValue(['loans', key, 'pmi', 'value'], mortgageInsurance);
      } else {
        form.setFieldValue(['loans', key, 'pmi', 'value'], value);
      }

    },
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Mortgage insurance is a type of insurance that protects your lender in case you default on your mortgage loan.
        It is typically required when your down payment is less than 20% of the purchase price.
      </div>
    ),
  },
  {
    id: 19,
    tabIndex: 10,
    order: 9,
    active: true,
    name: 'uip',
    note: 'uipNote',
    disabled: (loan) => Boolean(loan.addUip),
    text: 'Upfront ins. Premium',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    details: () => (
      <div style={{ maxWidth: 300 }}>
        What's this Upfront Insurance fee? All FHA loans require an upfront mortgage insurance premium (UFMIP), which
        helps protect lenders if you can't make your payments. It's a one-time fee of 1.75% of your loan amount. Think
        of it like a safety net: It reduces the lender's risk, allowing them to offer you a mortgage with a lower down
        payment (as low as 3.5%). This can be helpful if you're saving for a home but haven't reached a 20% down payment
        yet.
      </div>
    ),
    prepareData: (loan) => {
      const { loanAmount, uip, addUip } = loan;

      if (uip?.value && uip?.value !== '0' && !addUip) return toFixed(uip.value, 2);

      if (!loanAmount || !loanAmount?.value) return '0';

      const newUip = calculateUip(loanAmount.value);

      return Number.isNaN(uip) ? '0' : toFixed(newUip, 2);
    },
    onChange: (form, key, value, _, loan) => {
      const oldValue = loan?.uip?.value || 0; 
      const closingCosts = form.getFieldValue(['loans', key, 'closingCosts', 'value']);

      const newTotal = Number(closingCosts) - Number(oldValue) + Number(value);

      form.setFieldValue(['loans', key, 'closingCosts', 'value'], newTotal);
      form.setFieldValue(['loans', key, 'uip', 'value'], value);
    },
  },
  {
    id: 9,
    tabIndex: 11,
    order: 10,
    active: true,
    name: 'hoaDues',
    text: 'HOA Dues',
    note: 'hoaNote',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Homeowners Association (HOA) dues are monthly, quarterly, or annual fees that homeowners pay to their local HOA.
        HOAs are nonprofit organizations that are responsible for maintaining and managing common areas in a community.
        This can include things like landscaping, swimming pools, clubhouses, and tennis courts. In this section, you
        will see HOA dues calculated as a monthly total.
      </div>
    ),
  },
  {
    id: 10,
    tabIndex: 12,
    order: 11,
    active: true,
    name: 'propertyTaxes',
    note: 'propertyTaxesNote',
    text: 'Property Taxes',
    prepareData: (loan) => {
      const { propertyTaxes } = loan;

      return Number.isNaN(propertyTaxes?.value) ? 0 : toFixed(Number(propertyTaxes?.value), 2);
    },
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Property taxes are a type of tax that is paid on the value of your home and land. Your monthly property tax
        payment is calculated by dividing your annual property tax bill by 12. This section is an estimate of your
        monthly property tax cost.
      </div>
    ),
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    onChange: (form, key, value) => {
      const purchasePrice = form.getFieldValue(['loans', key, 'purchasePrice', 'value']);

      if (!purchasePrice) return;

      const percentage = (Number(value) * 12 / toFloat(purchasePrice)) * 100;

      form.setFieldValue(['loans', key, 'propertyTaxes', 'value'], value);
      form.setFieldValue(['loans', key, 'propertyTaxesPercentage', 'value'], percentage.toFixed(2));
    },
    subField: {
      tabIndex: 10,
      name: 'propertyTaxesPercentage',
      order: 1,
      prefix: 'Tax Rate: ',
      options: () => TAX_RATE_OPTIONS,
      suffix: '%',
      placeholder: '0%',
      disabled: () => true,
      onChange: (form, key, value) => {

        const purchasePrice = form.getFieldValue(['loans', key, 'purchasePrice', 'value']);
        const newValue = toFloat(purchasePrice) * (Number(value) / 100) / 12;

        form.setFieldValue(['loans', key, 'propertyTaxesPercentage', 'value'], value);
        form.setFieldValue(['loans', key, 'propertyTaxes', 'value'], newValue);
      },
      prepareData: (loan) => {
        const { purchasePrice, propertyTaxes, propertyTaxesPercentage, zpid } = loan;

        if (zpid && propertyTaxesPercentage?.value) return toFixed(propertyTaxesPercentage.value, 2)

        if (!propertyTaxes?.value || !purchasePrice?.value) return 0;

        return Number.isNaN(propertyTaxesPercentage?.value) ? '0' : propertyTaxesPercentage?.value
      },
    },
  },
  {
    id: 11,
    tabIndex: 13,
    order: 12,
    active: true,
    name: 'homeInsurance',
    note: 'homeInsuranceNote',
    text: 'Homeowners Insurance',
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Homeowners insurance protects your home and belongings from unexpected events like fire, theft, and weather.
        Your monthly homeowner’s insurance cost is calculated by dividing your annual bill by 12.
      </div>
    ),
    prefix: '$',
    placeholder: '$0',
    extra: <GetQuote />,
    styles: { height: 75 },
    subName: 'homeInsurancePercentage',
    options: () => HOME_INSURANCE_OPTIONS,
    onChange: (form, itemKey, value) => {
      const purchasePrice = form.getFieldValue(['loans', itemKey, 'purchasePrice', 'value']);

      if (!purchasePrice) return;

      const numericValue = parseFloat(value);

      const isOptionValue = HOME_INSURANCE_OPTIONS.some((option) => option.value === numericValue);

      let newValue;

      if (isOptionValue) {
        const newHomeInsurance = (toFloat(purchasePrice) * (numericValue / 100)) / 12;
        const roundedHomeInsurance = Math.round(newHomeInsurance);

        form.setFieldValue(['loans', itemKey, 'homeInsurancePercentage'], numericValue);
        newValue = Number.isNaN(roundedHomeInsurance) ? '0' : roundedHomeInsurance.toString();
      } else {
        newValue = value;
        form.setFieldValue(['loans', itemKey, 'homeInsurancePercentage'], '');
      }

      form.setFieldValue(['loans', itemKey, 'homeInsurance', 'value'], newValue);
    },
    prepareData: (loan) => getFieldValue(loan.homeInsurance)
  },
  {
    id: 12,
    order: 13,
    active: true,
    name: 'principalInterest',
    text: 'Principal and Interest',
    prefix: '$',
    note: 'principalInterestNote',
    placeholder: '$0',
    styles: { height: 50 },
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Principal and interest is the portion of your monthly mortgage payment that goes towards paying down the
        principal balance of your loan and the interest that has accrued on the loan.
      </div>
    ),
    prepareData: (loan) => {
      const { loanAmount, interestRate, loanPeriodInYears, addUip, loanAmountWithUip, interestOnly } = loan;

      if (!loanAmount?.value || !interestRate?.value) return 0;

      const R = addUip && loanAmountWithUip ? toFloat(loanAmountWithUip?.value) : toFloat(loanAmount.value);

      const r = toFloat(interestRate.value) / 100 / 12;
      const n = (toFloat(loanPeriodInYears?.value) || 30) * 12;

      const x = (1 + r) ** n;
      const M = Math.round(R * ((r * x) / (x - 1)));

      if (interestOnly) {
        let value;

        const rate = interestRate?.value;

        if (R != null && rate != null) {
          value = (Number(R) * Number(rate)) / 100 / 12;

          return commafy(`${value.toFixed(2)}`);
        }

        return '0';
      }

      return Number.isNaN(M) ? '0' : commafy(M);
    },
  },
  {
    id: 13,
    tabIndex: 14,
    order: 14,
    active: true,
    name: 'supplyTaxBill',
    note: 'supplyTaxBillNote',
    text: 'Supplemental Tax Bill',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    onChange(form, key, value) {
      form.setFieldValue(['loans', key, 'supplyTaxBill', 'value'], value || '0');
    },
    prepareData: (loan) => {
      if ((!loan || !loan.zpid) || loan.supplyTaxBill?.value) return commafy(loan?.supplyTaxBill?.value) || '0';

      const parsedData = typeof loan.suplimentalData === 'string' ? JSON.parse(loan.suplimentalData) : null;

      if (!parsedData) return '0';

      const { state, taxHistory } = parsedData;
      let taxPaid;
      let taxAssessment;

      if (taxHistory.length >= 2) {
        taxPaid = taxHistory[0]?.taxPaid > taxHistory[1]?.taxPaid ? taxHistory[0]?.taxPaid : taxHistory[1]?.taxPaid;
        taxAssessment = taxHistory[0]?.taxPaid > taxHistory[1]?.taxPaid ? taxHistory[0]?.value : taxHistory[1]?.value;
      } else {
        taxPaid = taxHistory[0]?.taxPaid;
        taxAssessment = taxHistory[0]?.value;
      }

      if (taxPaid === undefined || taxAssessment === undefined) {
        return '0';
      }

      let supplementalTaxBill;

      if (supplementalStates[state]) {
        const paidValueRatio = Math.round(taxPaid) / taxAssessment;
        const valueDifference = Number(loan.purchasePrice.value) - Number(taxAssessment);

        supplementalTaxBill = Math.ceil(paidValueRatio * valueDifference);
      } else {
        supplementalTaxBill = 0;
      }

      return Number?.isNaN(supplementalTaxBill) || supplementalTaxBill === 0
        ? commafy(loan?.supplyTaxBill?.value)
        : commafy(supplementalTaxBill);
    },
    details: (isBorrower) => (
      <div className="flex-col gap-20" style={{ maxWidth: 620 }}>
        <div className="flex-col">
          <Typography.Text>Only California require supplemental property tax bill</Typography.Text>
        </div>
        {isBorrower ? (
          <Typography.Text>
            In these states, if there is a change in ownership (or new construction) on a property, the property's
            assessed value will be re-evaluated. If the re-evaluated value is higher than the previous assessed value,
            the new property owner will be sent a one-time supplemental property tax bill for the difference in assessed
            value. The supplemental tax bill is due within a specific timeframe, typically within 30 days of the bill
            date
          </Typography.Text>
        ) : (
          <Typography.Text>
            In these states, if there is a change in ownership (or new construction) on a property, the assessed value
            of the property will be re-evaluated. If the re-evaluated value is higher than the previous assessed value,
            the new property owner will be sent a one-time supplemental property tax bill for the difference in assessed
            value. The supplemental tax bill is due within a certain timeframe, typically within 30 days of the bill
            date.
          </Typography.Text>
        )}
        {isBorrower ? (
          <Typography.Text>
            The supplemental tax bill amount is calculated by taking your purchase price amount minus the current
            assessed value, multiplied by the property tax rate
          </Typography.Text>
        ) : (
          <Typography.Text>
            The supplemental tax bill amount is calculated by taking the new purchase price amount minus the current
            assessed value, multiplied by the property tax rate.
          </Typography.Text>
        )}

        <div className="flex-col">
          <Typography.Text className="fw-600">Example:</Typography.Text>
          <Typography.Text>$800k property purchase price</Typography.Text>
          <Typography.Text>-$300k current accessed value</Typography.Text>
          <Typography.Text>500k * 1% tax rate = supplemental tax bill amount of $5,000</Typography.Text>
        </div>
        {isBorrower ? (
          <Typography.Text>
            This one-time bill is separate from your biannual property tax bills.In this row, you may view the
            difference in the one-time property supplemental tax bill amount when comparing multiple properties. Your
            impound account generally does not cover supplemental property tax bills. Please reach out to me if you have
            any questions regarding this section.
          </Typography.Text>
        ) : (
          <Typography.Text>
            Show your borrowers the difference in cost when comparing multiple properties. Supplemental property tax
            bills are not typically covered by an impound account. Educate your borrowers about supplemental property
            taxes so they may prepare for the expense.
          </Typography.Text>
        )}
      </div>
    ),
  },
  {
    id: 14,
    tabIndex: 15,
    order: 15,
    active: true,
    name: 'closingCosts',
    text: 'Closing Costs',
    prefix: '$',
    placeholder: '$0',
    note: 'closingCostNote',
    styles: { height: 75 },
    extra: <ClosingCostsDetails />,
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Closing costs are fees you pay to finalize your home purchase. They can include things like loan origination
        fees, title insurance, and escrow. Closing costs typically range from 1% to 3% of the purchase price of your
        home. Loan points are optional fees you can pay to lower your interest rate. They're included in the total
        closing costs you see here.
      </div>
    ),
    prepareData(loan) {
      return loan?.closingCosts?.value
    },
    // prepareData(loan) {
    //   // @ts-ignore - It's okay to ignore this error
    //   const { loanClosingCosts, closingCosts } =
    //     loan as { loanClosingCosts: { amount: number }[] };

    //   const total = loanClosingCosts ?
    //     loanClosingCosts.reduce((sum: number, cost: { amount: number }) => sum + cost.amount, 0) : 0

    //   const originalClosingCosts = loan.closingCosts

    //   if (Number(originalClosingCosts)) {

    //     return Number(originalClosingCosts)
    //   }

    //   console.log("LOAN: ", loan)

    //   return closingCosts
    // },
  },
  {
    id: 15,
    order: 16,
    active: true,
    name: 'interestChange',
    note: 'interestChangeNote',
    text: 'Rate Shift Impact',
    details: () => (
      <div style={{ maxWidth: 300 }}>
        Interest rates can change while you're shopping for a home. On average, rates move up or down by 0.25% each
        week. See here how much your monthly payment would increase or decrease if interest rates went up or down by
        0.25%
      </div>
    ),
    prefix: '$',
    placeholder: '0%',
    styles: { height: 50 },
    disabled: () => true,
    prepareData: (loan) => {
      const {
        loanAmount,
        interestRate,
        total,
        pmi,
        propertyTaxes,
        homeInsurance,
        hoaDues
      } = loan

      if (!loanAmount?.value || !interestRate?.value) return 0

      const reportSettingsMap = new Map(
        ((loan.reportSettings as any) || []).map((item: any) => [item.name, item.active])
      )

      const fields = [
        { name: "principalInterest", value: calculatePrincipalInterest(loan, Number(interestRate.value) - 0.25) },
        { name: "propertyTaxes", value: extractValue(propertyTaxes) },
        { name: "pmi", value: extractValue(pmi) },
        { name: "homeInsurance", value: extractValue(homeInsurance) },
        { name: "hoaDues", value: extractValue(hoaDues) },
      ]

      const newTotal = fields.reduce((sum, field) => {
        const isActive = reportSettingsMap.get(field.name) ?? true

        return sum + (isActive ? field.value : 0)
      }, 0)

      const finalTotal = Math.round(Number.isNaN(newTotal) ? 0 : newTotal)

      const oldTotal = toFloat(total.value)

      const rateShiftImpact = oldTotal - Number(finalTotal)

      return commafy(Math.abs(rateShiftImpact), 2)
    },
  },
  {
    id: 16,
    tabIndex: 16,
    order: 17,
    active: true,
    name: 'rentalValue',
    text: 'Rental Value',
    note: 'rentalValueNote',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    details: () => (
      <div style={{ maxWidth: 300 }}>
        This section shows how much the subject property could rent for, based on current market conditions.
      </div>
    ),
  },
  {
    id: 17,
    tabIndex: 18,
    order: 18,
    active: true,
    name: 'loanDetails',
    text: 'Loan Details',
    styles: { minHeight: '70px' },
  },
  {
    id: 16,
    tabIndex: 16,
    order: 19,
    active: true,
    name: 'cashOut',
    text: 'Cash Out',
    note: 'cashOutNote',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
  },
  {
    id: 18,
    order: 100,
    active: true,
    name: 'total',
    note: 'totalNote',
    text: 'Total Payment',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    static: true,
    disabled: () => true,
    details: () => (
      <div className="flex-col gap-8" style={{ maxWidth: 300 }}>
        <Typography.Text>This number represents your total monthly housing payment. It includes:</Typography.Text>

        <Typography.Text>1. Principal and interest</Typography.Text>
        <Typography.Text>2. Property taxes</Typography.Text>
        <Typography.Text>3. Insurance</Typography.Text>
        <Typography.Text>4. Mortgage insurance (if applicable)</Typography.Text>
        <Typography.Text>5. HOA dues (if applicable)</Typography.Text>
      </div>
    ),
    bolded: true,
    // Total
    // @ts-ignore - It's okay to ignore this error
    additionalText: (loan, loans, reportType) => {
      if (!loans?.length || loans.length === 1 || !toFloat(loan?.total?.value)) return undefined;

      const visibleLoans = loans.filter(item => !item.hidden);
    
      if (!visibleLoans.length) return undefined;
    
      const allLoansHaveSameValue =
        visibleLoans.every(item => toFloat(item.total?.value) === toFloat(visibleLoans[0].total?.value));
    
      if (allLoansHaveSameValue) return undefined;
    
      const filteredLoans = visibleLoans;
      const comparisonLoan = filteredLoans[0];
      const currentValue = toFloat(loan.total?.value);
    
      const labels = [];
    
      if (reportType === 'purchase') {
        const [lowestLoan, highestLoan] = filteredLoans.reduce(
          ([lowest, highest], item) => [
            toFloat(item.total?.value) < toFloat(lowest.total?.value) ? item : lowest,
            toFloat(item.total?.value) > toFloat(highest.total?.value) ? item : highest
          ],
          [filteredLoans[0], filteredLoans[0]]
        );
    
        if (loan.id === highestLoan.id) {
          labels.push({ text: 'Highest', style: { color: 'var(--color-tag-red)' } });
        }
        if (loan.id === lowestLoan.id) {
          labels.push({ text: 'Lowest', style: { color: 'var(--color-tag-green)' } });
        }
      }
    
      if (comparisonLoan &&
        comparisonLoan.id !== loan.id &&
        !loan?.isSegment &&
        toFloat(comparisonLoan.total?.value) !== currentValue
      ) {
        const difference = Math.abs(currentValue - toFloat(comparisonLoan.total?.value)).toFixed(2);
        const formattedDifference = `$${commafy(difference)}`;
    
        if (currentValue > toFloat(comparisonLoan.total?.value)) {
          labels.push({
            text: `(Higher by ${formattedDifference})`,
            style: { color: 'var(--color-tag-red)', }
          });
        } else {
          labels.push({
            text: `(Lower by ${formattedDifference})`,
            style: { color: 'var(--color-tag-green)' }
          });
        }
      }
    
      if (labels.length === 0) return undefined;
    
      const combinedLabelText = labels.map(label => label.text).join(' ');
    
      return {
        text: (
          <div className="flex-row">
            <div style={{ display: 'inline', ...labels[0]?.style }}>
              {combinedLabelText}
            </div>
          </div>
        ),
      };
    },
    prepareData: (loan) => {
      const { principalInterest, propertyTaxes, pmi, homeInsurance, hoaDues } = loan;

      const reportSettingsMap = new Map(
        ((loan.reportSettings as any) || []).map((item: any) => [item.name, item.active])
      );

      const fields = [
        { name: "principalInterest", value: extractValue(principalInterest) },
        { name: "propertyTaxes", value: extractValue(propertyTaxes) },
        { name: "pmi", value: extractValue(pmi) },
        { name: "homeInsurance", value: extractValue(homeInsurance) },
        { name: "hoaDues", value: extractValue(hoaDues) },
      ];

      const total = fields.reduce((sum, field) => {
        const isActive = reportSettingsMap.get(field.name) ?? true;

        return sum + (isActive ? field.value : 0);
      }, 0);

      return Number.isNaN(total) ? 0 : commafy(total);
    }

  },
  {
    id: 20,
    tabIndex: 17,
    order: 20,
    active: true,
    name: 'totalCashToClose',
    text: 'Total Cash to Close',
    prefix: '$',
    placeholder: '$0',
    styles: { height: 50 },
    static: true,
    disabled: () => true,
    details: () => (
      <div className="flex-col gap-8" style={{ maxWidth: 300 }}>
        <Typography.Text>This number represents the total amount of money
          you need to bring to closing. It includes:</Typography.Text>

        <Typography.Text>1. Down Payment</Typography.Text>
        <Typography.Text>2. Closing Costs</Typography.Text>
        <Typography.Text>3. Prepaid Items (if applicable)</Typography.Text>
        <Typography.Text>4. Loan Points</Typography.Text>
        <Typography.Text>5. Other fees (if applicable)</Typography.Text>
      </div>
    ),
    prepareData: (loan) => {
      const { downPayment, closingCosts, prepaidItems, loanPoints, otherFees } = loan;

      const totalCashToClose =
        toFloat(getFieldValue(downPayment)) +
        toFloat(getFieldValue(closingCosts)) +
        toFloat(getFieldValue(prepaidItems)) +
        toFloat(getFieldValue(loanPoints)) +
        toFloat(getFieldValue(otherFees));

      return Number.isNaN(totalCashToClose) ? 0 : commafy(totalCashToClose);
    },
  }

];

export const DEFAULT_COLUMN: ILoanColumn = {
  loanPeriodInYears: {
    value: 30,
  },
  total: {
    value: 0,
  },
};

export const CLOSING_COST_EVENTS: IEvent[] = [
  {
    id: 0,
    key: 0,
    day: '1',
    duration: '1',
    label: '',
    color: 'var(--color-blue)',
    description: 'Wire deposit money to escrow',
    name: 'deposit_money',
    prepareData(loan) {
      if (!loan) return '0';

      const { deposit_money_price, purchasePrice } = loan;

      if (Number(deposit_money_price)) return deposit_money_price;

      return Number.isNaN(purchasePrice?.value) ? 0 : Number(purchasePrice?.value) * 0.03;
    },
  },
  {
    id: 1,
    key: 1,
    day: '3',
    duration: '1',
    label: '',
    color: 'var(--color-tag-purple)',
    description: 'Order home inspection and appraisal',
    name: 'order_home_inspection',
    prepareData: (loan) => {
      if (!loan) return '0';

      const { order_home_inspection_price } = loan;

      const price = toFloat(order_home_inspection_price);

      return String(Number.isNaN(price) ? 1000 : price);
    },
  },
  {
    id: 2,
    key: 2,
    day: '17',
    duration: '1',
    label: '',
    color: 'var(--color-tag-blue)',
    description: 'Wire escrow remaining down payment and closing costs.',
    name: 'down_payment',
    prepareData: (loan) => {
      if (!loan) return '0';

      const { deposit_money_price, downPayment, closingCosts } = loan;
      const downPaymentAmount = toFloat(downPayment?.value) || 0;
      const depositMoney = toFloat(deposit_money_price) || 0;
      const closingCostsAmount = toFloat(closingCosts?.value) || 0;

      return (downPaymentAmount - depositMoney - closingCostsAmount).toString();
    },
  },
  {
    id: 3,
    key: 3,
    day: '21',
    duration: '1',
    label: '',
    color: 'var(--color-tag-green)',
    description: 'It’s time to receive the keys to your new home!',
    name: 'close_of_escrow',
    disabled: true,
  },
];


export const HOW_LONG_TO_BOY_EVENTS = [
  {
    id: 0,
    key: 0,
    day: '',
    duration: '',
    label: 'Secure Financing',
    name: 'prepare',
    range: '',
    color: 'var(--color-blue)',
    description: 'Pre-qualify for your mortgage loan.',
  },
  {
    id: 1,
    key: 1,
    day: '',
    duration: '',
    label: 'Property Search',
    name: 'searchBid',
    range: '',
    color: 'var(--color-tag-purple)',
    description: 'Identify a home, negotiate a purchase price and terms, open escrow.',
  },
  {
    id: 2,
    key: 2,
    day: '',
    duration: '',
    label: 'Escrow - Finalize paperwork and get your keys!',
    name: 'close',
    range: '',
    color: 'var(--color-tag-green)',
    description: `Complete the financing requirements and sign loan documents.
     Escrow will transfer the property into your name.
     Congratulations!  You are now a homeowner!  Meet your real estate agent to pick up the keys to your new home!`,
  },
];

export const FILING_STATUS = [
  {
    value: 'single',
    price: 13850,
    label: 'Single $13,850',
    text: 'Single $13,850',
  },
  {
    value: 'fillingSeparately',
    price: 13850,
    label: 'Married, filing separately $13,850',
    text: 'Married, filing separately $13,850',
  },
  {
    value: 'fillingJointly',
    price: 27700,
    label: 'Married, filing jointly or widow $27,700',
    text: 'Married, filing jointly or widow $27,700',
  },
  {
    value: 'head',
    price: 20800,
    label: 'Head of household $20,800',
    text: 'Head of household $20,800',
  },
];

export enum FILING_STATUS_ENUM {
  SINGLE = 'single',
  FILLING_SEPARATELY = 'fillingSeparately',
  FILLING_JOINTLY = 'fillingJointly',
  HEAD = 'head',
}

export const FEDERAL_TAX_RATE = {
  single: [
    {
      value: '10',
      text: '10%',
      taxBrackets: '$0 to $11,000',
    },
    {
      value: '12',
      text: '12%',
      taxBrackets: '$11,001 to $44,725',
    },
    {
      value: '22',
      text: '22%',
      taxBrackets: '$44,726 to $95,375',
    },
    {
      value: '24',
      text: '24%',
      taxBrackets: '$95,376 to $182,100',
    },
    {
      value: '32',
      text: '32%',
      taxBrackets: '$182,101 to $231,250',
    },
    {
      value: '35',
      text: '35%',
      taxBrackets: '$231,251 to $578,125',
    },
    {
      value: '37',
      text: '37%',
      taxBrackets: '$578,126 or more',
    },
  ],
  fillingSeparately: [
    {
      value: '10',
      text: '10%',
      taxBrackets: '$0 to $22,000',
    },
    {
      value: '12',
      text: '12%',
      taxBrackets: '$22,001 to $89,450',
    },
    {
      value: '22',
      text: '22%',
      taxBrackets: '$89,451 to $190,750',
    },
    {
      value: '24',
      text: '24%',
      taxBrackets: '$190,751 to $364,200',
    },
    {
      value: '32',
      text: '32%',
      taxBrackets: '$364,201 to $462,500',
    },
    {
      value: '35',
      text: '35%',
      taxBrackets: '$462,501 to $693,750',
    },
    {
      value: '37',
      text: '37%',
      taxBrackets: '$693,751 or more',
    },
  ],
  fillingJointly: [
    {
      value: '10',
      text: '10%',
      taxBrackets: '$0 to $11,000',
    },
    {
      value: '12',
      text: '12%',
      taxBrackets: '$11,001 to $44,725',
    },
    {
      value: '22',
      text: '22%',
      taxBrackets: '$44,726 to $95,375',
    },
    {
      value: '24',
      text: '24%',
      taxBrackets: '$95,376 to $182,100',
    },
    {
      value: '32',
      text: '32%',
      taxBrackets: '$182,101 to $231,250',
    },
    {
      value: '35',
      text: '35%',
      taxBrackets: '$231,251 to $346,875',
    },
    {
      value: '37',
      text: '37%',
      taxBrackets: '$346,876 or more',
    },
  ],
  head: [
    {
      value: '10',
      text: '10%',
      taxBrackets: '$0 to $15,700',
    },
    {
      value: '12',
      text: '12%',
      taxBrackets: '$15,701 to $59,850',
    },
    {
      value: '22',
      text: '22%',
      taxBrackets: '$59,851 to $95,350',
    },
    {
      value: '24',
      text: '24%',
      taxBrackets: '$95,351 to $182,100',
    },
    {
      value: '32',
      text: '32%',
      taxBrackets: '$182,101 to $231,250',
    },
    {
      value: '35',
      text: '35%',
      taxBrackets: '$231,251 to $578,100',
    },
    {
      value: '37',
      text: '37%',
      taxBrackets: '$578,101 or more',
    },
  ],
};

export const BUY_DOWN_LABELS = {
  oneYearBuyDown: '1 Year buydown',
  twoYearsBuyDown: '2 Year buydown',
  threeYearsBuyDown: '3 Year buydown',
};

export const RENT_VS_OWN = [
  {
    id: 0,
    rent: () => ``,
    own: () => ``,
    icon: null,
    text: '',
  },
  {
    id: 1,
    rent: (data: JsonResult) => `Rents his home ($${commafy(data.securityDeposit)} security deposit)`,
    own: (data: JsonResult) => `Purchases an $${commafy(data.purchasePrice)} home`,
    icon: Home,
    text: 'intro',
  },
  {
    id: 2,
    rent: (data: JsonResult) => `Invests $${commafy(data.investmentInto)} into S&P 500`,
    own: (data: JsonResult) => `Down payment and closing costs = $${commafy(data.downPaymentAndClosingCosts)}`,
    icon: Wallet,
    text: 'Allocation',
  },
  {
    id: 3,
    rent: (data: JsonResult) => `Rent is $${commafy(data.rent)}`,
    own: (data: JsonResult) => `Total housing payment is $${commafy(data.totalHousingPayment)} (6% fixed)`,
    icon: Coins,
    text: 'Expenses',
  },
  {
    id: 4,
    rent: (data: JsonResult) => `Moves 2 years later, rents have increased to $${commafy(data.rentAmount)}`,
    own: (data: JsonResult) => `Refinances into 4.5% rate - to
    new housing payment is $${commafy(data.newPaymentRefinance)}`,
    icon: Calculator,
    text: 'Changes',
  },
  {
    id: 5,
    rent: () => 'Has no housing tax write-offs',
    own: (data: JsonResult) =>
      `Nets an extra $${commafy(data.netTotalAnnualTaxSavings)} a year in income tax savings. Invests into S&P 500`,
    icon: HandBanknote,
    text: 'adjustments',
  },
  {
    id: 6,
    rent: () => 'Rents increase over time',
    own: () => 'Fixed mortgage payment. Payment reduces gradually by rate refinancing',
    icon: TimeCoin,
    text: 'costs evolution',
  },
  {
    id: 7,
    rent: () => 'Lacks a sense of belonging. No stability. Can be asked to leave at anytime',
    own: () => `Sense of stability and safety. Better connected with neighbors and community`,
    icon: BankCoin,
    text: 'stability',
  },
  {
    id: 8,
    rent: () => 'Landlord can immediately begin eviction for late rent payments',
    own: () =>
      `Bank foreclosures last about 18 months, offering ample time to secure your home and protect your family`,
    icon: DollarSheald,
    text: 'security',
  },
];

export const RENT_VS_OWN_RESULT = [
  {
    id: 0,
    rent: (data: JsonResult) => `Struggling financially with rent at $${commafy(data?.futureRentCost || 0)}.
    No retirement savings.`,
    own: (data: JsonResult) =>
      `Comfortably retiring with $${commafy(
        (data?.equity || 0) + (data?.stocksBalOwner || 0)
      )} in assets. Housing payment remains at $3,850.`,
    icon: Piggy,
    text: 'Result',
  },
  {
    id: 1,
    rent: () => '$0.00',
    own: (data: JsonResult) => `$${commafy(data.homeValue)}`,
    icon: CardPin,
    text: 'HOME VALUE',
  },
  {
    id: 2,
    rent: () => '$0.00',
    own: (data: JsonResult) => `$${commafy(data.loanAmount15years)} (Equity $${commafy(data.equity)})`,
    icon: BankPercent,
    text: 'loan amount',
  },
  {
    id: 3,
    rent: (data: JsonResult) => `$${commafy(data?.stocksBalRenter)}
    (must tap into retirement to cover high rents)`,
    own: (data: JsonResult) => `$${commafy(data?.stocksBalOwner)}`,
    icon: CointGrowth,
    text: 'Stocks',
  },
  {
    id: 4,
    rent: (data: JsonResult) => `$${commafy(data?.stocksBalRenter)}`,
    own: (data: JsonResult) => {
      const stocksBalOwner = data?.stocksBalOwner ?? 0;
      const loanAmount15years = data?.loanAmount15years ?? 0;
      const homeValue = data?.homeValue ?? 0;
      const total = stocksBalOwner + loanAmount15years + homeValue;

      return `$${commafy(total)}`;
    },
    icon: MoneyBug,
    text: 'Total Net Worth',
  },
];

export const RENT_VS_OWN_DATASETS: IDataSet[] = [
  {
    label: 'Brother 1',
    color: '#DE2626',
    name: 'rent',
  },
  {
    label: 'Brother 2',
    color: '#36AF51',
    name: 'own',
  },
];

export const RENT_VS_OWN_DATA = [
  {
    price: 250000,
    loanAmount: 237500,
    purchasePrice: 250000,
    rent: 1750,
    startingSavingsBalance: 19750,
    securityDeposit: 3500,
    investmentInto: 16250,
    downPayment: 12500,
    closingCosts: 3750,
    averageMoRentIncrease: 1847.62,
    downPaymentAndClosingCosts: 16250,
    futureRentCost: 4059.53,
    totalHousingPayment: 1501.16,
    rentAmount: 1957.78,
    newPaymentRefinance: 1176.81,
    netTotalAnnualTaxSavings: 2988.86,
    loanAmount15years: 168123.6,
    stocksBalRenter: 140849.22,
    stocksBalOwner: 122551.29,
    housingPaymentStill: 1501.16,
    homeValue: 519732.04,
    equity: 351608.44,
    averageAnnualLoanAmountReduction: 4625.09,
    averageAppreciationRate: 17982.14,
  },
  {
    price: 300000,
    loanAmount: 285000,
    purchasePrice: 300000,
    rent: 2000,
    startingSavingsBalance: 23500,
    securityDeposit: 4000,
    investmentInto: 19500,
    downPayment: 15000,
    closingCosts: 4500,
    averageMoRentIncrease: 2111.57,
    downPaymentAndClosingCosts: 19500,
    futureRentCost: 4639.46,
    totalHousingPayment: 1801.39,
    rentAmount: 2237.46,
    newPaymentRefinance: 1412.17,
    netTotalAnnualTaxSavings: 3586.65,
    loanAmount15years: 201748.18,
    stocksBalRenter: 156818.62,
    stocksBalOwner: 147062.28,
    housingPaymentStill: 1801.39,
    homeValue: 623678.45,
    equity: 421930.28,
    averageAnnualLoanAmountReduction: 5550.12,
    averageAppreciationRate: 21578.56,
  },
  {
    price: 350000,
    loanAmount: 332500,
    purchasePrice: 350000,
    rent: 2250,
    startingSavingsBalance: 27250,
    securityDeposit: 4500,
    investmentInto: 22750,
    downPayment: 17500,
    closingCosts: 5250,
    averageMoRentIncrease: 2375.52,
    downPaymentAndClosingCosts: 22750,
    futureRentCost: 5219.39,
    totalHousingPayment: 2101.63,
    rentAmount: 2517.14,
    newPaymentRefinance: 1647.53,
    netTotalAnnualTaxSavings: 4184.42,
    loanAmount15years: 235372.75,
    stocksBalRenter: 172788.01,
    stocksBalOwner: 171572.45,
    housingPaymentStill: 2101.63,
    homeValue: 727624.86,
    equity: 492252.11,
    averageAnnualLoanAmountReduction: 6475.15,
    averageAppreciationRate: 25174.99,
  },
  {
    price: 400000,
    loanAmount: 380000,
    purchasePrice: 400000,
    rent: 2500,
    startingSavingsBalance: 31000,
    securityDeposit: 5000,
    investmentInto: 26000,
    downPayment: 20000,
    closingCosts: 6000,
    averageMoRentIncrease: 2639.46,
    downPaymentAndClosingCosts: 26000,
    futureRentCost: 5799.33,
    totalHousingPayment: 2401.86,
    rentAmount: 2796.82,
    newPaymentRefinance: 1882.89,
    netTotalAnnualTaxSavings: 4782.19,
    loanAmount15years: 268997.33,
    stocksBalRenter: 188757.4,
    stocksBalOwner: 196082.62,
    housingPaymentStill: 2401.86,
    homeValue: 831571.27,
    equity: 562573.95,
    averageAnnualLoanAmountReduction: 7400.18,
    averageAppreciationRate: 28771.42,
  },
  {
    price: 450000,
    loanAmount: 427500,
    purchasePrice: 450000,
    rent: 2750,
    startingSavingsBalance: 34750,
    securityDeposit: 5500,
    investmentInto: 29250,
    downPayment: 22500,
    closingCosts: 6750,
    averageMoRentIncrease: 2903.41,
    downPaymentAndClosingCosts: 29250,
    futureRentCost: 6379.26,
    totalHousingPayment: 2702.09,
    rentAmount: 3076.51,
    newPaymentRefinance: 2118.25,
    netTotalAnnualTaxSavings: 5379.98,
    loanAmount15years: 302622.63,
    stocksBalRenter: 204726.79,
    stocksBalOwner: 220593.22,
    housingPaymentStill: 2702.09,
    homeValue: 935517.68,
    equity: 632895.06,
    averageAnnualLoanAmountReduction: 8325.16,
    averageAppreciationRate: 32367.85,
  },
  {
    price: 500000,
    loanAmount: 475000,
    purchasePrice: 500000,
    rent: 3000,
    startingSavingsBalance: 38500,
    securityDeposit: 6000,
    investmentInto: 32500,
    downPayment: 25000,
    closingCosts: 7500,
    averageMoRentIncrease: 3167.35,
    downPaymentAndClosingCosts: 32500,
    futureRentCost: 6959.19,
    totalHousingPayment: 3002.32,
    rentAmount: 3356.19,
    newPaymentRefinance: 2353.61,
    netTotalAnnualTaxSavings: 5977.75,
    loanAmount15years: 336247.2,
    stocksBalRenter: 220696.19,
    stocksBalOwner: 245103.39,
    housingPaymentStill: 3002.32,
    homeValue: 1039464.09,
    equity: 703216.89,
    averageAnnualLoanAmountReduction: 9250.19,
    averageAppreciationRate: 35964.27,
  },
  {
    price: 550000,
    loanAmount: 522500,
    purchasePrice: 550000,
    rent: 3250,
    startingSavingsBalance: 42250,
    securityDeposit: 6500,
    investmentInto: 35750,
    downPayment: 27500,
    closingCosts: 8250,
    averageMoRentIncrease: 3431.3,
    downPaymentAndClosingCosts: 35750,
    futureRentCost: 7539.12,
    totalHousingPayment: 3302.56,
    rentAmount: 3635.87,
    newPaymentRefinance: 2588.97,
    netTotalAnnualTaxSavings: 6575.52,
    loanAmount15years: 369871.78,
    stocksBalRenter: 236665.58,
    stocksBalOwner: 269613.56,
    housingPaymentStill: 3302.56,
    homeValue: 1143410.5,
    equity: 773538.72,
    averageAnnualLoanAmountReduction: 10175.21,
    averageAppreciationRate: 39560.7,
  },
  {
    price: 600000,
    loanAmount: 570000,
    purchasePrice: 600000,
    rent: 3500,
    startingSavingsBalance: 46000,
    securityDeposit: 7000,
    investmentInto: 39000,
    downPayment: 30000,
    closingCosts: 9000,
    averageMoRentIncrease: 3695.25,
    downPaymentAndClosingCosts: 39000,
    futureRentCost: 8119.06,
    totalHousingPayment: 3602.79,
    rentAmount: 3915.55,
    newPaymentRefinance: 2824.33,
    netTotalAnnualTaxSavings: 7173.31,
    loanAmount15years: 403496.35,
    stocksBalRenter: 252634.97,
    stocksBalOwner: 294124.56,
    housingPaymentStill: 3602.79,
    homeValue: 1247356.91,
    equity: 843860.56,
    averageAnnualLoanAmountReduction: 11100.24,
    averageAppreciationRate: 43157.13,
  },
  {
    price: 650000,
    loanAmount: 617500,
    purchasePrice: 650000,
    rent: 3750,
    startingSavingsBalance: 49750,
    securityDeposit: 7500,
    investmentInto: 42250,
    downPayment: 32500,
    closingCosts: 9750,
    averageMoRentIncrease: 3959.19,
    downPaymentAndClosingCosts: 42250,
    futureRentCost: 8698.99,
    totalHousingPayment: 3903.02,
    rentAmount: 4195.23,
    newPaymentRefinance: 3059.7,
    netTotalAnnualTaxSavings: 7771.08,
    loanAmount15years: 437120.93,
    stocksBalRenter: 268604.36,
    stocksBalOwner: 318634.73,
    housingPaymentStill: 3903.02,
    homeValue: 1351303.32,
    equity: 914182.39,
    averageAnnualLoanAmountReduction: 12025.27,
    averageAppreciationRate: 46753.55,
  },
  {
    price: 700000,
    loanAmount: 665000,
    purchasePrice: 700000,
    rent: 4000,
    startingSavingsBalance: 53500,
    securityDeposit: 8000,
    investmentInto: 45500,
    downPayment: 35000,
    closingCosts: 10500,
    averageMoRentIncrease: 4223.14,
    downPaymentAndClosingCosts: 45500,
    futureRentCost: 9278.92,
    totalHousingPayment: 4203.25,
    rentAmount: 4474.92,
    newPaymentRefinance: 3295.06,
    netTotalAnnualTaxSavings: 8368.86,
    loanAmount15years: 470746.23,
    stocksBalRenter: 284573.76,
    stocksBalOwner: 343145.32,
    housingPaymentStill: 4203.25,
    homeValue: 1455249.73,
    equity: 984503.5,
    averageAnnualLoanAmountReduction: 12950.25,
    averageAppreciationRate: 50349.98,
  },
  {
    price: 750000,
    loanAmount: 712500,
    purchasePrice: 750000,
    rent: 4250,
    startingSavingsBalance: 57250,
    securityDeposit: 8500,
    investmentInto: 48750,
    downPayment: 37500,
    closingCosts: 11250,
    averageMoRentIncrease: 4487.08,
    downPaymentAndClosingCosts: 48750,
    futureRentCost: 9858.86,
    totalHousingPayment: 4503.48,
    rentAmount: 4754.6,
    newPaymentRefinance: 3530.42,
    netTotalAnnualTaxSavings: 8966.63,
    loanAmount15years: 504370.8,
    stocksBalRenter: 300543.15,
    stocksBalOwner: 367655.5,
    housingPaymentStill: 4503.48,
    homeValue: 1559196.13,
    equity: 1054825.33,
    averageAnnualLoanAmountReduction: 13875.28,
    averageAppreciationRate: 53946.41,
  },
  {
    price: 800000,
    loanAmount: 760000,
    purchasePrice: 800000,
    rent: 4394,
    startingSavingsBalance: 60788,
    securityDeposit: 8788,
    investmentInto: 52000,
    downPayment: 40000,
    closingCosts: 12000,
    averageMoRentIncrease: 4639.12,
    downPaymentAndClosingCosts: 52000,
    futureRentCost: 10192.9,
    totalHousingPayment: 4803.72,
    rentAmount: 4915.7,
    newPaymentRefinance: 3765.78,
    netTotalAnnualTaxSavings: 9564.4,
    loanAmount15years: 537995.38,
    stocksBalRenter: 303580.06,
    stocksBalOwner: 392165.67,
    housingPaymentStill: 4803.72,
    homeValue: 1663142.54,
    equity: 1125147.17,
    averageAnnualLoanAmountReduction: 14800.31,
    averageAppreciationRate: 57542.84,
  },
  {
    price: 850000,
    loanAmount: 807500,
    purchasePrice: 850000,
    rent: 4538,
    startingSavingsBalance: 64326,
    securityDeposit: 9076,
    investmentInto: 55250,
    downPayment: 42500,
    closingCosts: 12750,
    averageMoRentIncrease: 4791.15,
    downPaymentAndClosingCosts: 55250,
    futureRentCost: 10526.94,
    totalHousingPayment: 5103.95,
    rentAmount: 5076.79,
    newPaymentRefinance: 4001.14,
    netTotalAnnualTaxSavings: 10162.19,
    loanAmount15years: 571619.95,
    stocksBalRenter: 306616.98,
    stocksBalOwner: 416676.66,
    housingPaymentStill: 5103.95,
    homeValue: 1767088.95,
    equity: 1195469,
    averageAnnualLoanAmountReduction: 15725.34,
    averageAppreciationRate: 61139.26,
  },
  {
    price: 900000,
    loanAmount: 855000,
    purchasePrice: 900000,
    rent: 4682,
    startingSavingsBalance: 67864,
    securityDeposit: 9364,
    investmentInto: 58500,
    downPayment: 45000,
    closingCosts: 13500,
    averageMoRentIncrease: 4943.18,
    downPaymentAndClosingCosts: 58500,
    futureRentCost: 10860.98,
    totalHousingPayment: 5404.18,
    rentAmount: 5237.89,
    newPaymentRefinance: 4236.51,
    netTotalAnnualTaxSavings: 10759.97,
    loanAmount15years: 605245.25,
    stocksBalRenter: 309653.89,
    stocksBalOwner: 441187.25,
    housingPaymentStill: 5404.18,
    homeValue: 1871035.36,
    equity: 1265790.11,
    averageAnnualLoanAmountReduction: 16650.32,
    averageAppreciationRate: 64735.69,
  },
  {
    price: 950000,
    loanAmount: 902500,
    purchasePrice: 950000,
    rent: 4826,
    startingSavingsBalance: 71402,
    securityDeposit: 9652,
    investmentInto: 61750,
    downPayment: 47500,
    closingCosts: 14250,
    averageMoRentIncrease: 5095.22,
    downPaymentAndClosingCosts: 61750,
    futureRentCost: 11195.02,
    totalHousingPayment: 5704.41,
    rentAmount: 5398.99,
    newPaymentRefinance: 4471.87,
    netTotalAnnualTaxSavings: 11357.74,
    loanAmount15years: 638869.83,
    stocksBalRenter: 312690.8,
    stocksBalOwner: 465697.43,
    housingPaymentStill: 5704.41,
    homeValue: 1974981.77,
    equity: 1336111.95,
    averageAnnualLoanAmountReduction: 17575.34,
    averageAppreciationRate: 68332.12,
  },
  {
    price: 1000000,
    loanAmount: 950000,
    purchasePrice: 1000000,
    rent: 4930,
    startingSavingsBalance: 74860,
    securityDeposit: 9860,
    investmentInto: 65000,
    downPayment: 50000,
    closingCosts: 15000,
    averageMoRentIncrease: 5205.02,
    downPaymentAndClosingCosts: 65000,
    futureRentCost: 11436.27,
    totalHousingPayment: 6004.65,
    rentAmount: 5515.34,
    newPaymentRefinance: 4707.23,
    netTotalAnnualTaxSavings: 11955.51,
    loanAmount15years: 672494.4,
    stocksBalRenter: 310847.54,
    stocksBalOwner: 490207.6,
    housingPaymentStill: 6004.65,
    homeValue: 2078928.18,
    equity: 1406433.78,
    averageAnnualLoanAmountReduction: 18500.37,
    averageAppreciationRate: 71928.55,
  },
  {
    price: 1050000,
    loanAmount: 997500,
    purchasePrice: 1050000,
    rent: 5034,
    startingSavingsBalance: 78318,
    securityDeposit: 10068,
    investmentInto: 68250,
    downPayment: 52500,
    closingCosts: 15750,
    averageMoRentIncrease: 5314.82,
    downPaymentAndClosingCosts: 68250,
    futureRentCost: 11677.52,
    totalHousingPayment: 6304.88,
    rentAmount: 5631.68,
    newPaymentRefinance: 4942.59,
    netTotalAnnualTaxSavings: 12553.28,
    loanAmount15years: 706118.98,
    stocksBalRenter: 309004.27,
    stocksBalOwner: 514717.77,
    housingPaymentStill: 6304.88,
    homeValue: 2182874.59,
    equity: 1476755.61,
    averageAnnualLoanAmountReduction: 19425.4,
    averageAppreciationRate: 75524.97,
  },
  {
    price: 1100000,
    loanAmount: 880000,
    purchasePrice: 1100000,
    rent: 5138,
    startingSavingsBalance: 246776,
    securityDeposit: 10276,
    investmentInto: 236500,
    downPayment: 220000,
    closingCosts: 16500,
    averageMoRentIncrease: 5424.62,
    downPaymentAndClosingCosts: 236500,
    futureRentCost: 11918.78,
    totalHousingPayment: 5562.2,
    rentAmount: 5748.03,
    newPaymentRefinance: 4360.38,
    netTotalAnnualTaxSavings: 11134.59,
    loanAmount15years: 622942.47,
    stocksBalRenter: 430604.11,
    stocksBalOwner: 456547.76,
    housingPaymentStill: 5562.2,
    homeValue: 2286821,
    equity: 1663878.53,
    averageAnnualLoanAmountReduction: 17137.17,
    averageAppreciationRate: 79121.4,
  },
  {
    price: 1150000,
    loanAmount: 920000,
    purchasePrice: 1150000,
    rent: 5242,
    startingSavingsBalance: 257734,
    securityDeposit: 10484,
    investmentInto: 247250,
    downPayment: 230000,
    closingCosts: 17250,
    averageMoRentIncrease: 5534.42,
    downPaymentAndClosingCosts: 247250,
    futureRentCost: 12160.03,
    totalHousingPayment: 5815.03,
    rentAmount: 5864.38,
    newPaymentRefinance: 4558.58,
    netTotalAnnualTaxSavings: 11577.96,
    loanAmount15years: 651257.45,
    stocksBalRenter: 465982.16,
    stocksBalOwner: 474727.01,
    housingPaymentStill: 5815.03,
    homeValue: 2390767.41,
    equity: 1739509.96,
    averageAnnualLoanAmountReduction: 17916.17,
    averageAppreciationRate: 82717.83,
  },
  {
    price: 1200000,
    loanAmount: 960000,
    purchasePrice: 1200000,
    rent: 5346,
    startingSavingsBalance: 268692,
    securityDeposit: 10692,
    investmentInto: 258000,
    downPayment: 240000,
    closingCosts: 18000,
    averageMoRentIncrease: 5644.22,
    downPaymentAndClosingCosts: 258000,
    futureRentCost: 12401.28,
    totalHousingPayment: 6067.85,
    rentAmount: 5980.73,
    newPaymentRefinance: 4756.78,
    netTotalAnnualTaxSavings: 12081.36,
    loanAmount15years: 679573.14,
    stocksBalRenter: 501360.2,
    stocksBalOwner: 495367.66,
    housingPaymentStill: 6067.85,
    homeValue: 2494713.82,
    equity: 1815140.67,
    averageAnnualLoanAmountReduction: 18695.12,
    averageAppreciationRate: 86314.25,
  },
  {
    price: 1250000,
    loanAmount: 1000000,
    purchasePrice: 1250000,
    rent: 5450,
    startingSavingsBalance: 279650,
    securityDeposit: 10900,
    investmentInto: 268750,
    downPayment: 250000,
    closingCosts: 18750,
    averageMoRentIncrease: 5754.03,
    downPaymentAndClosingCosts: 268750,
    futureRentCost: 12642.53,
    totalHousingPayment: 6320.68,
    rentAmount: 6097.07,
    newPaymentRefinance: 5056.31,
    netTotalAnnualTaxSavings: 12789.54,
    loanAmount15years: 722366.28,
    stocksBalRenter: 536738.25,
    stocksBalOwner: 524404.8,
    housingPaymentStill: 6320.68,
    homeValue: 2598660.22,
    equity: 1876293.94,
    averageAnnualLoanAmountReduction: 18508.91,
    averageAppreciationRate: 89910.68,
  },
  {
    price: 1300000,
    loanAmount: 1040000,
    purchasePrice: 1300000,
    rent: 5554,
    startingSavingsBalance: 290608,
    securityDeposit: 11108,
    investmentInto: 279500,
    downPayment: 260000,
    closingCosts: 19500,
    averageMoRentIncrease: 5863.83,
    downPaymentAndClosingCosts: 279500,
    futureRentCost: 12883.78,
    totalHousingPayment: 6573.51,
    rentAmount: 6213.42,
    newPaymentRefinance: 5153.18,
    netTotalAnnualTaxSavings: 13088.14,
    loanAmount15years: 736204.54,
    stocksBalRenter: 572116.29,
    stocksBalOwner: 536648.13,
    housingPaymentStill: 6573.51,
    homeValue: 2702606.63,
    equity: 1966402.09,
    averageAnnualLoanAmountReduction: 20253.03,
    averageAppreciationRate: 93507.11,
  },
  {
    price: 1350000,
    loanAmount: 1080000,
    purchasePrice: 1350000,
    rent: 5658,
    startingSavingsBalance: 301566,
    securityDeposit: 11316,
    investmentInto: 290250,
    downPayment: 270000,
    closingCosts: 20250,
    averageMoRentIncrease: 5973.63,
    downPaymentAndClosingCosts: 290250,
    futureRentCost: 13125.04,
    totalHousingPayment: 6826.33,
    rentAmount: 6329.77,
    newPaymentRefinance: 5351.38,
    netTotalAnnualTaxSavings: 13591.53,
    loanAmount15years: 764520.24,
    stocksBalRenter: 607494.34,
    stocksBalOwner: 557288.77,
    housingPaymentStill: 6826.33,
    homeValue: 2806553.04,
    equity: 2042032.8,
    averageAnnualLoanAmountReduction: 21031.98,
    averageAppreciationRate: 97103.54,
  },
  {
    price: 1400000,
    loanAmount: 1120000,
    purchasePrice: 1400000,
    rent: 5762,
    startingSavingsBalance: 312524,
    securityDeposit: 11524,
    investmentInto: 301000,
    downPayment: 280000,
    closingCosts: 21000,
    averageMoRentIncrease: 6083.43,
    downPaymentAndClosingCosts: 301000,
    futureRentCost: 13366.29,
    totalHousingPayment: 7079.16,
    rentAmount: 6446.12,
    newPaymentRefinance: 5549.58,
    netTotalAnnualTaxSavings: 14094.93,
    loanAmount15years: 792835.94,
    stocksBalRenter: 642872.38,
    stocksBalOwner: 577929.42,
    housingPaymentStill: 7079.16,
    homeValue: 2910499.45,
    equity: 2117663.51,
    averageAnnualLoanAmountReduction: 21810.94,
    averageAppreciationRate: 100699.96,
  },
  {
    price: 1450000,
    loanAmount: 1160000,
    purchasePrice: 1450000,
    rent: 5866,
    startingSavingsBalance: 323482,
    securityDeposit: 11732,
    investmentInto: 311750,
    downPayment: 290000,
    closingCosts: 21750,
    averageMoRentIncrease: 6193.23,
    downPaymentAndClosingCosts: 311750,
    futureRentCost: 13607.54,
    totalHousingPayment: 7331.99,
    rentAmount: 6562.47,
    newPaymentRefinance: 5747.77,
    netTotalAnnualTaxSavings: 14598.32,
    loanAmount15years: 821150.91,
    stocksBalRenter: 678250.43,
    stocksBalOwner: 598569.65,
    housingPaymentStill: 7331.99,
    homeValue: 3014445.86,
    equity: 2193294.95,
    averageAnnualLoanAmountReduction: 22589.94,
    averageAppreciationRate: 104296.39,
  },
  {
    price: 1500000,
    loanAmount: 1200000,
    purchasePrice: 1500000,
    rent: 5970,
    startingSavingsBalance: 334440,
    securityDeposit: 11940,
    investmentInto: 322500,
    downPayment: 300000,
    closingCosts: 22500,
    averageMoRentIncrease: 6303.03,
    downPaymentAndClosingCosts: 322500,
    futureRentCost: 13848.79,
    totalHousingPayment: 7584.82,
    rentAmount: 6678.81,
    newPaymentRefinance: 5945.97,
    netTotalAnnualTaxSavings: 15101.7,
    loanAmount15years: 849466.61,
    stocksBalRenter: 713628.47,
    stocksBalOwner: 619209.47,
    housingPaymentStill: 7584.82,
    homeValue: 3118392.27,
    equity: 2268925.66,
    averageAnnualLoanAmountReduction: 23368.89,
    averageAppreciationRate: 107892.82,
  },
  {
    price: 1550000,
    loanAmount: 1240000,
    purchasePrice: 1550000,
    rent: 6074,
    startingSavingsBalance: 345398,
    securityDeposit: 12148,
    investmentInto: 333250,
    downPayment: 310000,
    closingCosts: 23250,
    averageMoRentIncrease: 6412.84,
    downPaymentAndClosingCosts: 333250,
    futureRentCost: 14090.04,
    totalHousingPayment: 7837.64,
    rentAmount: 6795.16,
    newPaymentRefinance: 6144.17,
    netTotalAnnualTaxSavings: 15605.09,
    loanAmount15years: 877782.31,
    stocksBalRenter: 749006.52,
    stocksBalOwner: 639850.12,
    housingPaymentStill: 7837.64,
    homeValue: 3222338.68,
    equity: 2344556.37,
    averageAnnualLoanAmountReduction: 24147.85,
    averageAppreciationRate: 111489.25,
  },
  {
    price: 1600000,
    loanAmount: 1280000,
    purchasePrice: 1600000,
    rent: 6178,
    startingSavingsBalance: 356356,
    securityDeposit: 12356,
    investmentInto: 344000,
    downPayment: 320000,
    closingCosts: 24000,
    averageMoRentIncrease: 6522.64,
    downPaymentAndClosingCosts: 344000,
    futureRentCost: 14331.3,
    totalHousingPayment: 8090.47,
    rentAmount: 6911.51,
    newPaymentRefinance: 6342.37,
    netTotalAnnualTaxSavings: 16108.49,
    loanAmount15years: 906098.01,
    stocksBalRenter: 784384.56,
    stocksBalOwner: 660490.76,
    housingPaymentStill: 8090.47,
    homeValue: 3326285.09,
    equity: 2420187.08,
    averageAnnualLoanAmountReduction: 24926.8,
    averageAppreciationRate: 115085.67,
  },
  {
    price: 1650000,
    loanAmount: 1320000,
    purchasePrice: 1650000,
    rent: 6282,
    startingSavingsBalance: 367314,
    securityDeposit: 12564,
    investmentInto: 354750,
    downPayment: 330000,
    closingCosts: 24750,
    averageMoRentIncrease: 6632.44,
    downPaymentAndClosingCosts: 354750,
    futureRentCost: 14572.55,
    totalHousingPayment: 8343.3,
    rentAmount: 7027.86,
    newPaymentRefinance: 6540.57,
    netTotalAnnualTaxSavings: 16611.89,
    loanAmount15years: 934413.71,
    stocksBalRenter: 819762.61,
    stocksBalOwner: 681131.41,
    housingPaymentStill: 8343.3,
    homeValue: 3430231.5,
    equity: 2495817.79,
    averageAnnualLoanAmountReduction: 25705.75,
    averageAppreciationRate: 118682.1,
  },
  {
    price: 1700000,
    loanAmount: 1360000,
    purchasePrice: 1700000,
    rent: 6386,
    startingSavingsBalance: 378272,
    securityDeposit: 12772,
    investmentInto: 365500,
    downPayment: 340000,
    closingCosts: 25500,
    averageMoRentIncrease: 6742.24,
    downPaymentAndClosingCosts: 365500,
    futureRentCost: 14813.8,
    totalHousingPayment: 8596.13,
    rentAmount: 7144.21,
    newPaymentRefinance: 6738.77,
    netTotalAnnualTaxSavings: 17115.26,
    loanAmount15years: 962728.68,
    stocksBalRenter: 855140.65,
    stocksBalOwner: 701770.81,
    housingPaymentStill: 8596.13,
    homeValue: 3534177.9,
    equity: 2571449.22,
    averageAnnualLoanAmountReduction: 26484.75,
    averageAppreciationRate: 122278.53,
  },
  {
    price: 1750000,
    loanAmount: 1400000,
    purchasePrice: 1750000,
    rent: 6490,
    startingSavingsBalance: 389230,
    securityDeposit: 12980,
    investmentInto: 376250,
    downPayment: 350000,
    closingCosts: 26250,
    averageMoRentIncrease: 6852.04,
    downPaymentAndClosingCosts: 376250,
    futureRentCost: 15055.05,
    totalHousingPayment: 8848.95,
    rentAmount: 7260.55,
    newPaymentRefinance: 6936.97,
    netTotalAnnualTaxSavings: 17618.66,
    loanAmount15years: 991044.38,
    stocksBalRenter: 890518.7,
    stocksBalOwner: 722411.46,
    housingPaymentStill: 8848.95,
    homeValue: 3638124.31,
    equity: 2647079.93,
    averageAnnualLoanAmountReduction: 27263.71,
    averageAppreciationRate: 125874.95,
  },
  {
    price: 1800000,
    loanAmount: 1440000,
    purchasePrice: 1800000,
    rent: 6594,
    startingSavingsBalance: 400188,
    securityDeposit: 13188,
    investmentInto: 387000,
    downPayment: 360000,
    closingCosts: 27000,
    averageMoRentIncrease: 6961.84,
    downPaymentAndClosingCosts: 387000,
    futureRentCost: 15296.3,
    totalHousingPayment: 9101.78,
    rentAmount: 7376.9,
    newPaymentRefinance: 7135.17,
    netTotalAnnualTaxSavings: 18122.05,
    loanAmount15years: 1019360.08,
    stocksBalRenter: 925896.74,
    stocksBalOwner: 743052.11,
    housingPaymentStill: 9101.78,
    homeValue: 3742070.72,
    equity: 2722710.64,
    averageAnnualLoanAmountReduction: 28042.66,
    averageAppreciationRate: 129471.38,
  },
  {
    price: 1850000,
    loanAmount: 1480000,
    purchasePrice: 1850000,
    rent: 6698,
    startingSavingsBalance: 411146,
    securityDeposit: 13396,
    investmentInto: 397750,
    downPayment: 370000,
    closingCosts: 27750,
    averageMoRentIncrease: 7071.64,
    downPaymentAndClosingCosts: 397750,
    futureRentCost: 15537.56,
    totalHousingPayment: 9354.61,
    rentAmount: 7493.25,
    newPaymentRefinance: 7333.37,
    netTotalAnnualTaxSavings: 18625.45,
    loanAmount15years: 1047675.78,
    stocksBalRenter: 961274.79,
    stocksBalOwner: 763692.75,
    housingPaymentStill: 9354.61,
    homeValue: 3846017.13,
    equity: 2798341.36,
    averageAnnualLoanAmountReduction: 28821.61,
    averageAppreciationRate: 133067.81,
  },
  {
    price: 1900000,
    loanAmount: 1520000,
    purchasePrice: 1900000,
    rent: 6802,
    startingSavingsBalance: 422104,
    securityDeposit: 13604,
    investmentInto: 408500,
    downPayment: 380000,
    closingCosts: 28500,
    averageMoRentIncrease: 7181.45,
    downPaymentAndClosingCosts: 408500,
    futureRentCost: 15778.81,
    totalHousingPayment: 9607.43,
    rentAmount: 7609.6,
    newPaymentRefinance: 7531.57,
    netTotalAnnualTaxSavings: 19128.83,
    loanAmount15years: 1075991.48,
    stocksBalRenter: 996652.83,
    stocksBalOwner: 784332.58,
    housingPaymentStill: 9607.43,
    homeValue: 3949963.54,
    equity: 2873972.07,
    averageAnnualLoanAmountReduction: 29600.57,
    averageAppreciationRate: 136664.24,
  },
  {
    price: 1950000,
    loanAmount: 1560000,
    purchasePrice: 1950000,
    rent: 6906,
    startingSavingsBalance: 433062,
    securityDeposit: 13812,
    investmentInto: 419250,
    downPayment: 390000,
    closingCosts: 29250,
    averageMoRentIncrease: 7291.25,
    downPaymentAndClosingCosts: 419250,
    futureRentCost: 16020.06,
    totalHousingPayment: 9860.26,
    rentAmount: 7725.94,
    newPaymentRefinance: 7729.77,
    netTotalAnnualTaxSavings: 19632.23,
    loanAmount15years: 1104307.17,
    stocksBalRenter: 1032030.88,
    stocksBalOwner: 804973.22,
    housingPaymentStill: 9860.26,
    homeValue: 4053909.95,
    equity: 2949602.78,
    averageAnnualLoanAmountReduction: 30379.52,
    averageAppreciationRate: 140260.66,
  },
  {
    price: 2000000,
    loanAmount: 1600000,
    purchasePrice: 2000000,
    rent: 7010,
    startingSavingsBalance: 444020,
    securityDeposit: 14020,
    investmentInto: 430000,
    downPayment: 400000,
    closingCosts: 30000,
    averageMoRentIncrease: 7401.05,
    downPaymentAndClosingCosts: 430000,
    futureRentCost: 16261.31,
    totalHousingPayment: 10113.09,
    rentAmount: 7842.29,
    newPaymentRefinance: 7927.96,
    netTotalAnnualTaxSavings: 20135.62,
    loanAmount15years: 1132622.15,
    stocksBalRenter: 1067408.92,
    stocksBalOwner: 825613.45,
    housingPaymentStill: 10113.09,
    homeValue: 4157856.36,
    equity: 3025234.21,
    averageAnnualLoanAmountReduction: 31158.52,
    averageAppreciationRate: 143857.09,
  },
];

export const RENT_VS_OWN_CHART_DATA = [
  {
    price: 250000,
    rent: [
      17956.25, 19841.66, 20713.33, 20394.91, 18687.5, 15367.03, 10181.4, 2847.26, 6953.52, 19577.84, 35425.71,
      54945.11, 78637.4, 107063.23, 140849.22,
    ],
    own: [
      46417.019, 83282.826, 104471.233, 127884.423, 153755.998, 182344.089, 213933.929, 248840.702, 287412.687,
      330034.729, 377132.087, 429174.666, 486681.717, 550227, 620444.554,
    ],
  },
  {
    price: 300000,
    rent: [
      21547.5, 23809.99, 24925.24, 24692.88, 22886.92, 19252.72, 13503.77, 5318.03, 5666.14, 19854.45, 37701.68,
      59717.16, 86470.95, 118600.58, 156818.62,
    ],
    own: [
      55700.461, 99939.469, 125365.606, 153461.487, 184507.436, 218813.21, 256721.089, 298609.296, 344895.765,
      396042.313, 452559.248, 515010.462, 584019.053, 660273.546, 744534.761,
    ],
  },
  {
    price: 350000,
    rent: [
      25138.75, 27778.32, 29137.14, 28990.85, 27086.33, 23138.41, 16826.15, 7788.8, 4378.76, 20131.07, 39977.65,
      64489.22, 94304.5, 130137.93, 172788.01,
    ],
    own: [
      64983.882, 116596.071, 146259.913, 179038.458, 215258.751, 255282.174, 299508.057, 348377.657, 402378.566,
      462049.569, 527986.029, 600845.816, 681355.881, 770319.503, 868624.306,
    ],
  },
  {
    price: 400000,
    rent: [
      28730, 31746.65, 33349.05, 33288.82, 31285.75, 27024.09, 20148.52, 10259.57, 3091.38, 20407.69, 42253.63,
      69261.27, 102138.05, 141675.28, 188757.4,
    ],
    own: [
      74267.303, 133252.672, 167154.219, 204615.429, 246010.065, 291751.138, 342295.024, 398146.018, 459861.366,
      528056.826, 603412.809, 686681.171, 778692.71, 880365.461, 992713.851,
    ],
  },
  {
    price: 450000,
    rent: [
      32321.25, 35714.98, 37560.95, 37586.79, 35485.16, 30909.78, 23470.9, 12730.34, 1804, 20684.3, 44529.6, 74033.32,
      109971.61, 153212.63, 204726.79,
    ],
    own: [
      83550.638, 149909.092, 188048.226, 230191.97, 276760.806, 328219.37, 385081.083, 447913.276, 517342.85,
      594062.528, 678837.773, 772514.419, 876027.112, 990408.639, 1116800.225,
    ],
  },
  {
    price: 500000,
    rent: [
      35912.5, 39683.31, 41772.86, 41884.76, 39684.58, 34795.47, 26793.27, 15201.12, 516.62, 20960.92, 46805.58,
      78805.37, 117805.16, 164749.97, 220696.19,
    ],
    own: [
      92834.059, 166565.694, 208942.533, 255768.94, 307512.12, 364688.334, 427868.051, 497681.637, 574825.651,
      660069.785, 754264.554, 858349.773, 973363.941, 1100454.596, 1240889.77,
    ],
  },
  {
    price: 550000,
    rent: [
      39503.75, 43651.64, 45984.77, 46182.72, 43883.99, 38681.16, 30115.65, 17671.89, 770.76, 21237.53, 49081.55,
      83577.42, 125638.71, 176287.32, 236665.58,
    ],
    own: [
      102117.48, 183222.295, 229836.839, 281345.911, 338263.435, 401157.299, 470655.018, 547449.998, 632308.451,
      726077.042, 829691.335, 944185.128, 1070700.77, 1210500.554, 1364979.315,
    ],
  },
  {
    price: 600000,
    rent: [
      43095, 47619.98, 50196.67, 50480.69, 48083.41, 42566.85, 33438.03, 20142.66, 2058.14, 21514.15, 51357.53,
      88349.48, 133472.27, 187824.67, 252634.97,
    ],
    own: [
      111400.921, 199878.939, 250731.212, 306922.975, 369014.872, 437626.419, 513442.178, 597218.592, 689791.53,
      792084.625, 905118.496, 1030020.923, 1168038.105, 1320547.092, 1489069.521,
    ],
  },
  {
    price: 650000,
    rent: [
      46686.25, 51588.31, 54408.58, 54778.66, 52282.82, 46452.53, 36760.4, 22613.43, 3345.53, 21790.77, 53633.5,
      93121.53, 141305.82, 199362.02, 268604.36,
    ],
    own: [
      120684.342, 216535.54, 271625.519, 332499.946, 399766.187, 474095.384, 556229.146, 646986.953, 747274.33,
      858091.882, 980545.277, 1115856.278, 1265374.934, 1430593.049, 1613159.066,
    ],
  },
  {
    price: 700000,
    rent: [
      50277.5, 55556.64, 58620.48, 59076.63, 56482.24, 50338.22, 40082.78, 25084.2, 4632.91, 22067.38, 55909.48,
      97893.58, 149139.37, 210899.37, 284573.76,
    ],
    own: [
      129967.677, 233191.96, 292519.526, 358076.486, 430516.928, 510563.615, 599015.205, 696754.211, 804755.814,
      924097.584, 1055970.241, 1201689.526, 1362709.336, 1540636.227, 1737245.441,
    ],
  },
  {
    price: 750000,
    rent: [
      53868.75, 59524.97, 62832.39, 63374.6, 60681.65, 54223.91, 43405.15, 27554.97, 5920.29, 22344, 58185.45,
      102665.63, 156972.93, 222436.72, 300543.15,
    ],
    own: [
      139251.098, 249848.562, 313413.833, 383653.457, 461268.242, 547032.579, 641802.172, 746522.572, 862238.615,
      990104.841, 1131397.021, 1287524.881, 1460046.165, 1650682.184, 1861334.986,
    ],
  },
  {
    price: 800000,
    rent: [
      57460, 63493.3, 67117.69, 67904.69, 65370.7, 58970.62, 48090.79, 32041.13, 10046.44, 18763.33, 55363.71, 100847.7,
      156438.92, 223506.25, 303580.06,
    ],
    own: [
      148534.519, 266505.163, 334308.139, 409230.428, 492019.556, 583501.544, 684589.14, 796290.933, 919721.415,
      1056112.098, 1206823.802, 1373360.235, 1557382.994, 1760728.142, 1985424.531,
    ],
  },
  {
    price: 850000,
    rent: [
      61051.25, 67461.63, 71402.99, 72434.78, 70059.74, 63717.32, 52776.42, 36527.3, 14172.59, 15182.65, 52541.96,
      99029.76, 155904.91, 224575.79, 306616.98,
    ],
    own: [
      157630.46, 282767.119, 354578.882, 433930.881, 521614.839, 618505.613, 725569.918, 843875.976, 974604.169,
      1119058.822, 1278681.215, 1455063.958, 1649966.889, 1865334.629, 2103315.98,
    ],
  },
  {
    price: 900000,
    rent: [
      64642.5, 71429.96, 75688.29, 76964.88, 74748.79, 68464.03, 57462.06, 41013.47, 18298.74, 11601.98, 49720.22,
      97211.83, 155370.9, 225645.32, 309653.89,
    ],
    own: [
      166726.295, 299028.852, 374849.26, 458630.811, 551209.425, 653508.794, 766549.596, 891459.682, 1029485.328,
      1182003.666, 1350536.43, 1536765.134, 1742547.852, 1969937.755, 2221203.598,
    ],
  },
  {
    price: 950000,
    rent: [
      68233.75, 75398.29, 79973.59, 81494.97, 79437.83, 73210.74, 62147.7, 45499.63, 22424.88, 8021.31, 46898.48,
      95393.89, 154836.9, 226714.86, 312690.8,
    ],
    own: [
      175822.216, 315290.766, 395119.937, 483331.171, 580804.585, 688512.707, 807530.182, 939044.492, 1084367.804,
      1244950.064, 1422393.462, 1618468.416, 1835131.24, 2074543.661, 2339094.387,
    ],
  },
  {
    price: 1000000,
    rent: [
      71825, 79366.63, 84286.59, 86112.66, 84311.64, 78282.36, 67347.77, 50746.33, 27622.27, 2985.05, 42153.07,
      91089.17, 151145.32, 223834.27, 310847.54,
    ],
    own: [
      184918.138, 331552.679, 415390.614, 508031.531, 610399.744, 723516.62, 848510.768, 986629.301, 1139250.28,
      1307896.462, 1494250.493, 1700171.698, 1927714.629, 2179149.568, 2456985.175,
    ],
  },
  {
    price: 1050000,
    rent: [
      75416.25, 83334.96, 88599.59, 90730.34, 89185.45, 83353.98, 72547.85, 55993.02, 32819.65, 2051.2, 37407.66,
      86784.45, 147453.74, 220953.69, 309004.27,
    ],
    own: [
      194014.059, 347814.593, 435661.29, 532731.89, 639994.904, 758520.533, 889491.354, 1034214.11, 1194132.756,
      1370842.86, 1566107.525, 1781874.98, 2020298.018, 2283755.474, 2574875.963,
    ],
  },
  {
    price: 1100000,
    rent: [
      261332.5, 288772.41, 315535.96, 341346.87, 365887.98, 388796.33, 409657.58, 427999.89, 443287.03, 454910.79,
      462182.54, 464323.77, 460455.7, 449587.63, 430604.11,
    ],
    own: [
      205666.997, 369459.029, 453945.792, 547303.664, 650464.113, 764456.409, 890417.896, 1029605.339, 1183407.464,
      1353358.812, 1541155.052, 1748669.896, 1977973.8, 2231354.613, 2511340.411,
    ],
  },
  {
    price: 1150000,
    rent: [
      273211.25, 301898.43, 329968.21, 357146.32, 383117.64, 407521.16, 429944.46, 449917.5, 466905.77, 480302.65,
      489420.89, 493483.2, 491611.72, 482816.43, 465982.16,
    ],
    own: [
      214816.462, 385833.653, 473917.707, 571250.585, 678803.417, 797649.295, 928973.991, 1074087.78, 1234438.517,
      1411626.081, 1607418.339, 1823768.785, 2062836.027, 2327005.329, 2618912.409,
    ],
  },
  {
    price: 1200000,
    rent: [
      285090, 315024.45, 344400.45, 372945.77, 400347.3, 426246, 450231.34, 471835.11, 490524.52, 505694.51, 516659.25,
      522642.63, 522767.75, 516045.23, 501360.2,
    ],
    own: [
      224025.861, 402334.437, 494088.95, 595477.686, 707512.24, 831310.422, 968107.413, 1119268.087, 1286300.633,
      1470871.596, 1674822.511, 1900188.271, 2149217.436, 2424394.663, 2728465.499,
    ],
  },
  {
    price: 1250000,
    rent: [
      296968.75, 328150.47, 358832.69, 388745.22, 417576.96, 444970.83, 470518.21, 493752.72, 514143.26, 531086.37,
      543897.6, 551802.05, 553923.78, 549274.03, 536738.25,
    ],
    own: [
      231509.714, 415202.948, 508270.103, 611109.31, 724746.633, 850315.874, 989069.887, 1142393.07, 1311815.188,
      1499026.629, 1705895.27, 1934485.119, 2187076.902, 2466190.822, 2774611.704,
    ],
  },
  {
    price: 1300000,
    rent: [
      308847.5, 341276.49, 373264.93, 404544.68, 434806.62, 463695.67, 490805.09, 515670.33, 537762.01, 556478.23,
      571135.96, 580961.48, 585079.8, 582502.83, 572116.29,
    ],
    own: [
      242444.638, 435335.964, 534431.37, 643931.794, 764929.763, 898632.518, 1046374.063, 1209628.469, 1390024.589,
      1589362.301, 1809630.472, 2053026.802, 2321979.746, 2619172.75, 2947571.019,
    ],
  },
  {
    price: 1350000,
    rent: [
      320726.25, 354402.51, 387697.17, 420344.13, 452036.29, 482420.5, 511091.97, 537587.94, 561380.76, 581870.09,
      598374.31, 610120.91, 616235.83, 615731.63, 607494.34,
    ],
    own: [
      251654.037, 451836.748, 554602.614, 668158.895, 793638.586, 932293.644, 1085507.484, 1254808.777, 1441886.705,
      1648607.816, 1877034.644, 2129446.288, 2408361.155, 2716562.084, 3057124.109,
    ],
  },
  {
    price: 1400000,
    rent: [
      332605, 367528.53, 402129.41, 436143.58, 469265.95, 501145.34, 531378.85, 559505.55, 584999.5, 607261.95,
      625612.66, 639280.34, 647391.86, 648960.43, 642872.38,
    ],
    own: [
      260863.436, 468337.533, 574773.857, 692385.996, 822347.409, 965954.771, 1124640.905, 1299989.084, 1493748.822,
      1707853.332, 1944438.815, 2205865.774, 2494742.564, 2813951.417, 3166677.2,
    ],
  },
  {
    price: 1450000,
    rent: [
      344483.75, 380654.54, 416561.65, 451943.03, 486495.61, 519870.17, 551665.73, 581423.16, 608618.25, 632653.81,
      652851.02, 668439.76, 678547.88, 682189.23, 678250.43,
    ],
    own: [
      270072.921, 484838.499, 594945.4, 716613.527, 851056.806, 999616.63, 1163775.235, 1345170.494, 1545612.255,
      1767100.402, 2011844.803, 2282287.367, 2581126.399, 2911343.53, 3276233.46,
    ],
  },
  {
    price: 1500000,
    rent: [
      356362.5, 393780.56, 430993.89, 467742.49, 503725.27, 538595.01, 571952.6, 603340.77, 632237, 658045.67,
      680089.37, 697599.19, 709703.91, 715418.03, 713628.47,
    ],
    own: [
      279282.3, 501339.241, 615116.577, 740840.534, 879765.506, 1033277.6, 1202908.464, 1390350.569, 1597474.095,
      1826345.591, 2079248.594, 2358706.412, 2667507.301, 3008732.284, 3385785.89,
    ],
  },
  {
    price: 1550000,
    rent: [
      368241.25, 406906.58, 445426.13, 483541.94, 520954.93, 557319.84, 592239.48, 625258.38, 655855.74, 683437.53,
      707327.73, 726758.62, 740859.94, 748646.83, 749006.52,
    ],
    own: [
      288491.698, 517840.025, 635287.821, 765067.635, 908474.329, 1066938.726, 1242041.885, 1435530.876, 1649336.211,
      1885591.106, 2146652.765, 2435125.898, 2753888.71, 3106121.617, 3495338.98,
    ],
  },
  {
    price: 1600000,
    rent: [
      380120, 420032.6, 459858.38, 499341.39, 538184.59, 576044.68, 612526.36, 647176, 679474.49, 708829.39, 734566.08,
      755918.05, 772015.96, 781875.63, 784384.56,
    ],
    own: [
      297701.097, 534340.81, 655459.064, 789294.735, 937183.152, 1100599.853, 1281175.307, 1480711.184, 1701198.327,
      1944836.621, 2214056.936, 2511545.384, 2840270.119, 3203510.951, 3604892.07,
    ],
  },
  {
    price: 1650000,
    rent: [
      391998.75, 433158.62, 474290.62, 515140.84, 555414.25, 594769.51, 632813.24, 669093.61, 703093.24, 734221.25,
      761804.44, 785077.47, 803171.99, 815104.43, 819762.61,
    ],
    own: [
      306910.496, 550841.594, 675630.308, 813521.836, 965891.975, 1134260.979, 1320308.728, 1525891.491, 1753060.444,
      2004082.137, 2281461.108, 2587964.87, 2926651.528, 3300900.285, 3714445.161,
    ],
  },
  {
    price: 1700000,
    rent: [
      403877.5, 446284.64, 488722.86, 530940.3, 572643.92, 613494.35, 653100.11, 691011.22, 726711.98, 759613.11,
      789042.79, 814236.9, 834328.01, 848333.23, 855140.65,
    ],
    own: [
      316119.961, 567342.518, 695801.784, 837749.273, 994601.249, 1167922.682, 1359442.865, 1571072.668, 1804923.6,
      2063328.88, 2348866.715, 2664386.022, 3013034.856, 3398291.818, 3824000.76,
    ],
  },
  {
    price: 1750000,
    rent: [
      415756.25, 459410.66, 503155.1, 546739.75, 589873.58, 632219.18, 673386.99, 712928.83, 750330.73, 785004.97,
      816281.14, 843396.33, 865484.04, 881562.03, 890518.7,
    ],
    own: [
      325329.36, 583843.302, 715973.027, 861976.374, 1023310.072, 1201583.808, 1398576.287, 1616252.976, 1856785.717,
      2122574.396, 2416270.886, 2740805.508, 3099416.265, 3495681.151, 3933553.851,
    ],
  },
  {
    price: 1800000,
    rent: [
      427635, 472536.68, 517587.34, 562539.2, 607103.24, 650944.02, 693673.87, 734846.44, 773949.48, 810396.82,
      843519.5, 872555.76, 896640.07, 914790.83, 925896.74,
    ],
    own: [
      334538.758, 600344.086, 736144.271, 886203.475, 1052018.895, 1235244.935, 1437709.708, 1661433.283, 1908647.833,
      2181819.911, 2483675.057, 2817224.994, 3185797.674, 3593070.485, 4043106.941,
    ],
  },
  {
    price: 1850000,
    rent: [
      439513.75, 485662.69, 532019.58, 578338.65, 624332.9, 669668.85, 713960.75, 756764.05, 797568.22, 835788.68,
      870757.85, 901715.19, 927796.09, 948019.63, 961274.79,
    ],
    own: [
      343748.157, 616844.871, 756315.514, 910430.576, 1080727.718, 1268906.061, 1476843.13, 1706613.59, 1960509.95,
      2241065.427, 2551079.229, 2893644.48, 3272179.083, 3690459.818, 4152660.032,
    ],
  },
  {
    price: 1900000,
    rent: [
      451392.5, 498788.71, 546451.82, 594138.1, 641562.56, 688393.69, 734247.62, 778681.66, 821186.97, 861180.54,
      897996.21, 930874.61, 958952.12, 981248.43, 996652.83,
    ],
    own: [
      352957.536, 633345.613, 776486.691, 934657.583, 1109436.418, 1302567.031, 1515976.358, 1751793.665, 2012371.789,
      2300310.616, 2618483.019, 2970063.525, 3358559.984, 3787848.572, 4262212.461,
    ],
  },
  {
    price: 1950000,
    rent: [
      463271.25, 511914.73, 560884.06, 609937.56, 658792.22, 707118.52, 754534.5, 800599.27, 844805.72, 886572.4,
      925234.56, 960034.04, 990108.15, 1014477.23, 1032030.88,
    ],
    own: [
      361757.985, 648985.558, 795236.43, 956843.643, 1135419.614, 1332746.061, 1550791.785, 1791732.311, 2057971.591,
      2352165.997, 2677250.814, 3036469.538, 3433406.227, 3872021.269, 4356690.89,
    ],
  },
  {
    price: 2000000,
    rent: [
      475150, 525040.75, 575316.3, 625737.01, 676021.88, 725843.36, 774821.38, 822516.88, 868424.46, 911964.26,
      952472.92, 989193.47, 1021264.17, 1047706.03, 1067408.92,
    ],
    own: [
      376410.323, 676943.731, 833572.368, 1006647.012, 1197894.493, 1409222.96, 1642740.916, 1900778.258, 2185909.52,
      2500979.565, 2849131.964, 3233840.366, 3658943.149, 4128681.725, 4647742.852,
    ],
  },
];

const TAX_RATE_OPTIONS = [
  {
    value: 1.25,
    label: '1.25%',
  },
  {
    value: 0.4,
    label: '0.4%',
  },
  {
    value: 0.5,
    label: '0.5%',
  },
  {
    value: 0.6,
    label: '0.6%',
  },
  {
    value: 0.7,
    label: '0.7%',
  },
  {
    value: 0.8,
    label: '0.8%',
  },
  {
    value: 0.9,
    label: '0.9%',
  },
  {
    value: 1,
    label: '1.0%',
  },
  {
    value: 1.1,
    label: '1.1%',
  },
  {
    value: 1.2,
    label: '1.2%',
  },
  {
    value: 1.3,
    label: '1.3%',
  },
  {
    value: 1.4,
    label: '1.4%',
  },
  {
    value: 1.5,
    label: '1.5%',
  },
  {
    value: 1.6,
    label: '1.6%',
  },
  {
    value: 1.7,
    label: '1.7%',
  },
  {
    value: 1.8,
    label: '1.8%',
  },
  {
    value: 1.9,
    label: '1.9%',
  },
  {
    value: 2,
    label: '2.0%',
  },
  {
    value: 2.1,
    label: '2.1%',
  },
  {
    value: 2.2,
    label: '2.2%',
  },
  {
    value: 2.3,
    label: '2.3%',
  },
  {
    value: 2.4,
    label: '2.4%',
  },
  {
    value: 2.5,
    label: '2.5%',
  },
];

const HOME_INSURANCE_OPTIONS = [
  { label: '0.15%', value: 0.15 },
  { label: '0.2%', value: 0.2 },
  { label: '0.25%', value: 0.25 },
  { label: '0.3%', value: 0.3 },
];

const MORTGAGE_INSURANCE_OPTIONS = [
  { label: '0.1%', value: 0.1 },
  { label: '0.15%', value: 0.15 },
  { label: '0.2%', value: 0.2 },
  { label: '0.25%', value: 0.25 },
  { label: '0.3%', value: 0.3 },
  { label: '0.55%', value: 0.55 },
]

export const getSuffixDetails = (row: IReportRow, loan: ILoanColumn) => {
  let suffix = '';
  let suffixContent: string | undefined;

  const noteValue = loan[row.note || ''] as string;

  switch (row.name) {
    case 'principalInterest':
      if (loan.interestOnly) {
        suffix = ` (Interest only) ${noteValue || ''}`;
        suffixContent = `(Interest only) ${noteValue || ''}`;
      } else {
        suffix = `${row.suffix || ''}  ${noteValue || ''}`;
        suffixContent = noteValue && noteValue !== '0' ? noteValue : '';
      }
      break;
    case 'purchasePrice':
      if (loan.zpid && loan.priceStatus) {
        suffix = ` ${noteValue || ''}`;
        suffixContent = `${loan?.priceStatus ? `${loan?.priceStatus}` : ''} ${noteValue || ''}`;
      } else {
        suffix = `${row.suffix || ''}  ${noteValue || ''}`;
        suffixContent = noteValue && noteValue !== '0' ? noteValue : '';
      }
      break;
    default:
      if (noteValue && noteValue !== '0') {
        suffix = `${row.suffix || ''}  ${noteValue}`;
        suffixContent = noteValue;
      } else {
        suffix = `${row.suffix || ''}`;
      }
      break;
  }

  return { suffix, suffixContent };
};

export const defaultDatasets: IDataSetRA[] = [
  {
    id: 1,
    label: 'Average',
    name: 'avg',
    value: true,
    color: '#22A4C9',
    isActive: false,
  },
  {
    id: 2,
    label: 'Studio',
    name: 'bedrooms',
    value: 0,
    color: '#7532A8',
    isActive: false,
  },
  {
    id: 3,
    label: '1BD',
    name: 'bedrooms',
    value: 1,
    color: '#9adfef',
    isActive: false,
  },
  {
    id: 4,
    label: '2BD',
    name: 'bedrooms',
    value: 2,
    color: '#e7b174',
    isActive: false,
  },
  {
    id: 5,
    label: '3BD',
    name: 'bedrooms',
    value: 3,
    color: '#d8b9f3',
    isActive: false,
  },
  {
    id: 6,
    label: '4BD',
    name: 'bedrooms',
    value: 4,
    color: '#a7ecb7',
    isActive: true,
  },
  {
    id: 7,
    label: '5BD',
    name: 'bedrooms',
    value: 5,
    color: '#ec9eb0',
    isActive: false,
  },
];

export const longTermDatasets: ILongTermDataSet[] = [
  {
    label: 'Tax Benefit',
    name: 'taxBeretta',
    color: '#1E0C68',
    details:
      'This pertains to the significant annual deductions you may receive on your income taxes by paying your ' +
      'mortgage interest and property taxes (depreciation is not included in this estimate).  Essentially, this ' +
      'number represents the amount of money you may save in the long run through income tax deductions associated ' +
      'with owning a home.',
  },
  {
    label: 'Appreciation Gain',
    name: 'appreciationGain',
    color: '#BDE7F1',
    details:
      "This is the projected annual increase in your property's value. For our calculations, we're " +
      "using the national average appreciation rate of 5.77%. In simpler terms, it's the potential profit you " +
      'stand to make each year simply by owning your home, as its market value increases',
  },
  {
    label: 'Amortization Gain',
    name: 'amortisationGain',
    color: '#22A4C9',
    details:
      "Consider this as your personal wealth-building strategy. This is the total sum that you've " +
      'successfully allocated towards reducing your primary loan balance.  Initially, a larger portion of your ' +
      'payment goes towards interest, but as time passes, more of your payment starts chipping away at the ' +
      "principal amount.  It's akin to a forced savings account; each monthly payment increases the equity in " +
      'your home.',
  },
];

export const REPORT_TYPE_OPTIONS = [
  { label: 'Purchase', value: EnumReportTypes.purchase },
  { label: 'Refinance', value: EnumReportTypes.refinance },
];

export const CLOSING_COST_TYPES = [
  { label: 'Flat Fee', value: EnumClosingCostType.flatFee },
  { label: 'Percentage of Loan Amount', value: EnumClosingCostType.percentageOfLoanAmount },
  { label: 'Prepaid Interest 15 Days', value: EnumClosingCostType.prepaidInterest15Days },
];
