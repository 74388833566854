import { useEffect, useState } from "react";
import { Button, Checkbox, Popover } from "antd";
import LoansClosingCosts from "./LoansClosingCosts";

import './closingCosts.less'
import { useReportCSUpdate } from "../../../../hooks/reports";
import { useContextReports } from "../../../../context/reports";

const DETAILS_AVAILABLE_DEFAULT = false

const ClosingCostsDetails = () => {
	const [detailsAvailable, setDetailsAvailable] = useState<boolean>(DETAILS_AVAILABLE_DEFAULT);
	const [closingCostsOpen, setClosingCostsOpen] = useState<boolean>(false);
	const {
		reportId,
		report,
		getReport,
		closingCostsLoading
	} = useContextReports();
	const { fetch: updateReport, loading } = useReportCSUpdate();

	useEffect(() => {
		if (report && report.csActive !== undefined) {
			setDetailsAvailable(report.csActive);
		}
	}, [report]);

	const handleUpdateDetailsAvailable = async (available: boolean) => {
		await updateReport({ csActive: available }, reportId)
			.then(() => {
				setDetailsAvailable(available);
				getReport?.();
			})
	}

	return (
		<div className="cost-options">
			<LoansClosingCosts open={closingCostsOpen} onCancel={() => setClosingCostsOpen(false)} />
			<Button
				type="default"
				onClick={() => setClosingCostsOpen(true)}
				disabled={detailsAvailable}
				loading={closingCostsLoading}
			>
				Details
			</Button>
			<Popover content="Custom amount.">
				<Checkbox
					onChange={(e) => handleUpdateDetailsAvailable(e.target.checked)}
					checked={detailsAvailable}
					disabled={loading}
				/>
			</Popover>
		</div>
	)
}

export default ClosingCostsDetails;
